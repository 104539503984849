/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import '../../compiled.css';

const FooterComponent: React.FC = () => {
  return (
    <footer className="bg-color-2 py-5">
      <div className="container-fluid text-center">
        <p className="lh-sm serif text-uppercase fs-3 m-0 fw-bold">© 2022 The ERC Group</p>
        <p className="m-0 fs-5">
          <br className="d-block d-lg-none" />
          <span className="d-none d-lg-inline mx-2" style={{ opacity: '.5' }}>
            |
          </span>
          Dallas, TX 75201
          <br className="d-block d-lg-none" />
          <span className="d-none d-lg-inline mx-2" style={{ opacity: '.5' }}>
            |
          </span>
          <a href="tel:(214) 305-9104" className="text-decoration-none white">
            (214) 305-9104
          </a>
        </p>
        <p className="mb-0 mt-2 text-uppercase">
          <a className="d-block d-sm-inline">Site Map</a>
          <span className="mx-1 d-none d-sm-inline">•</span>
          <a className="d-block d-sm-inline">Privacy Policy</a>
          <span className="mx-1 d-none d-sm-inline">•</span>
          <a className="d-block d-sm-inline">Disclaimer</a>
          <span className="mx-1 d-none d-md-inline">•</span>
          <br className="d-none d-sm-block d-md-none" />
          <a
            className="fa fa-facebook mx-1 text-decoration-none"
            target="_blank"
            title="Facebook"
            href="https://www.facebook.com/fileerc"
          ></a>

          <a
            className="fa fa-instagram mx-1 text-decoration-none"
            target="_blank"
            title="Instagram"
            href="https://www.instagram.com/fileerc/"
          ></a>
          <a
            className="fa fa-linkedin mx-1 text-decoration-none"
            target="_blank"
            title="LinkedIn"
            href="https://www.linkedin.com/company/the-erc-group/"
          ></a>
        </p>
      </div>
    </footer>
  );
};

export default FooterComponent;
