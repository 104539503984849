export enum ACTIONS {
	ERROR = "ERROR",
	LOGIN = "LOGIN",
	LOGOUT = "LOGOUT",
	REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET',
	PASSWORD_RESET = 'PASSWORD_RESET',
	SET_CLIENT_OPTIONS = 'SET_CLIENT_OPTIONS',

	//auth
	CONFIRM_EMAIL = 'CONFIRM_EMAIL',

	//user
	GET_USER = 'GET_USER',
	GET_USERS = 'GET_USERS',
	UPDATE_USER = 'UPDATE_USER',
	GET_USER_NOTES = 'GET_USER_NOTES',
	CREATE_USER_NOTE = 'CREATE_USER_NOTE',
	UPDATE_USER_NOTE = 'UPDATE_USER_NOTE',
	DELETE_USER_NOTE = 'DELETE_USER_NOTE',
	USER_ACTIVATION = 'USER_ACTIVATION',
	ADD_REMOVE_ROLE = 'ADD_REMOVE_ROLE',

	//REPORTING
	DOWNLOAD_USERS_REPORT = 'DOWNLOAD_USERS_REPORT',
	GET_USER_ROLES = 'GET_USER_ROLES',
	GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA',
	SET_TIMEZONE = 'SET_TIMEZONE',

	//RESOURCES
	CREATE_UPLOAD_RESOURCE = 'CREATE_UPLOAD_RESOURCE',
	UPLOAD_RESOURCE_FILE = 'UPLOAD_RESOURCE_FILE',
	GET_UPLOAD_URLS = 'GET_UPLOAD_URLS',
	UPLOAD_WAGE_SHEET = 'UPLOAD_WAGE_SHEET',

	//AWS
	INIT_PRESIGNED_URL_MULTIPART_UPLOAD = 'INIT_PRESIGNED_URL_MULTIPART_UPLOAD',
	INIT_PRESIGNED_URL_SIMPLE_UPLOAD = 'INIT_PRESIGNED_URL_SIMPLE_UPLOAD',
	COMPLETE_MULTIPART_UPLOAD = 'COMPLETE_MULTIPART_UPLOAD',
	INIT_PRESIGNED_URL_MULTIPART_UPLOAD_SUCCESS = "INIT_PRESIGNED_URL_MULTIPART_UPLOAD_SUCCESS",
	INIT_PRESIGNED_URL_SIMPLE_UPLOAD_SUCCESS = "INIT_PRESIGNED_URL_SIMPLE_UPLOAD_SUCCESS",
	CREATE_UPLOAD_RESOURCE_SUCCESS = "CREATE_UPLOAD_RESOURCE_SUCCESS",
	GET_PRESIGNED_UPLOAD_URLS_SUCCESS = 'GET_PRESIGNED_UPLOAD_URLS_SUCCESS',

	//DATA-COLLECTION
	CREATE_DATA_COLLECTION = 'CREATE_DATA_COLLECTION',
	CREATE_QUESTION_SET = 'CREATE_QUESTION_SET',
	CREATE_QUESTION = 'CREATE_QUESTION',
	GET_QUESTION_SETS = 'GET_QUESTION_SETS',
	GET_QUESTIONS = 'GET_QUESTIONS',
	GET_QUESTIONS_BY_UUID = 'GET_QUESTIONS_BY_UUID',
	GET_MY_DATA_COLLECTIONS = 'GET_MY_DATA_COLLECTIONS',

	UPDATE_QUESTION_SET='UPDATE_QUESTION_SET',
	UPDATE_QUESTION='UPDATE_QUESTION',

	UPLOAD_941='UPLOAD_941',
	RETURN_941='RETURN_941',

	UPLOAD_WAGE_WORKSHEET='UPLOAD_WAGE_WORKSHEET',
}

