
const isUpperCase = (str: any) => /^[A-Z]*$/.test(str)

export const enumValues = (enumVals: any) => {
	const returnArr = [] as any[]
	for (var item in enumVals) {
		returnArr.push(item as any)
	}
	return returnArr
}

export const safeBool = (
	input: string | boolean,
	defaultValue = false,
): boolean => {
	try {
		let newInput = input;
		if (typeof input === 'string') {
			newInput = input.toLowerCase();
		}
		if (newInput === 'true' || newInput === true) {
			return true;
		}
		if (newInput === 'false' || newInput === false) {
			return false;
		}
		return defaultValue;
	} catch (e) {
		return defaultValue;
	}
};

export const enumValuesToOrList = (enumVals: any) => {
	let returnString = ""
	let firstVal = true
	for (var item in enumVals) {
		if (!firstVal) {
			returnString += " OR "
		}
		firstVal = false;
		returnString += item;
	}
	return returnString
}


export const capFirstLetter = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export const safeSum = (a: number, b: number) => {
	let aa = Number(a);
	let bb = Number(b);
	if (isNaN(a)) {
		aa = 0
	}
	if (isNaN(b)) {
		bb = 0
	}
	return aa + bb;
}


export const hasNumberLetter6Length = (str: any) => {
	let hasNumber = false;
	let hasUpper = false;

	for (var i = 0; i < str.length; i++) {
		const char = str.charAt(i)
		const upperCase = isUpperCase(char)
		const isNumber = !isNaN(char)
		if (upperCase) {
			hasUpper = true;
		}
		if (isNumber) {
			hasNumber = true;
		}
	}
	if (str.length >= 6 && hasUpper && hasNumber) {
		return true;
	}
	return false;
}

export const makeSearchObject = (obj: any, include_fields = [] as any[]) => {
	const returnVals = [] as any[];
	for (const [key, value] of Object.entries(obj)) {
		switch (typeof value) {
			case 'string':
			case 'number':
				if (include_fields.length === 0 || include_fields.includes(key)) {
					returnVals.push(value)
				}
				break;
			default:
				break;
		}
	}
	return returnVals.join(' ');
}

export const roundNumberFixed2 = (num: any): number => {
	try {
		return Math.round((Number(num) + Number.EPSILON) * 100) / 100;
	}
	catch {
		return 0
	}
}

export const notificationExpirationDate = () => {
	return new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 7))
}