
export enum RESOURCE_TYPE {
	IMAGE = "image",
	VIDEO = "video",
	AUDIO = "audio",
  }
  
  export enum RESOURCE_STATUS {
	AWAITING_CONVERSION = 'awaiting_conversion',
	UNAVAILABLE = "unavailable",
	AVAILABLE = "available",
	DELETED = "deleted",
	ERROR = "error",
  }
  
  export enum FILE_TYPE {
	  jpg = 'jpg',
	  png = 'png'
  }

  export const FILE_CHUNK_SIZE = 7340032;