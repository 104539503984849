import { uniqBy } from "lodash";

const cellStyle = {
    width: 300,
    maxWidth: 300
  }

export const normalizeDynamicWeekData = (rowValues: any) => {
    const returnData = [] as any[];
    const returnColumns = [{
      title: 'Name', field: 'name', sorting: true, cellStyle
    }]
    rowValues.forEach((rowValue: any) => {
      const curItem = { name: rowValue.name } as any;
      Object.entries(rowValue.weeks).forEach(([kk, vv]) => {
        curItem[kk] = vv;
        returnColumns.push({
          title: kk, field: kk, sorting: true, cellStyle
        })
      })
      returnData.push(curItem);
    })
    console.log("RETURNS THIS", {
      data: returnData,
      columns: returnColumns
    })
    return {
      data: returnData,
      columns: uniqBy(returnColumns, 'title')
    }
  }

  export const getWageWorksheetData = (taxCalcs: any, yearAttribute: string) => { //calcs2021
    console.log("handling success?", taxCalcs)

    let data = taxCalcs[yearAttribute]
    let calcs = {} as any
    console.log("handling success 2")
    Object.entries(data).forEach(([k, v]) => {
      console.log("The key: ", k)
      console.log("The value: ", v)
      switch (k) {
        case "employees":
          const eData = normalizeDynamicWeekData(v);
          calcs.employeeData = eData.data
          calcs.dynamicWeekColumns = eData.columns
          break;
        case "lesPPP":
          const lData = normalizeDynamicWeekData(v);
          calcs.lessPPPData = lData.data
          calcs.dynamicWeekColumns = lData.columns
          break;
        case "eligibleWages":
          const ewData = normalizeDynamicWeekData(v);
          calcs.eligableWageData = ewData.data
          calcs.dynamicWeekColumns = ewData.columns
          break;
        case "quarters":
          const val = v as any;
          calcs.eligableByQuarter = val.eligableByQuarter
          calcs.qualifiedByQuarter = val.qualifiedByQuarter
          calcs.ercByQuarter = val.ercByQuarter
          let q1 = 0
          let q2 = 0
          let q3 = 0
          let q4 = 0
          val.ercByQuarter.map((x: any) => {
            if (x['Q1']) {
              q1 += x['Q1']
            };
            if (x['Q2']) {
              q2 += x['Q2']
            };
            if (x['Q3']) {
              q3 += x['Q3']
            };
            if (x['Q4']) {
              q4 += x['Q4']
            }
          })
          console.log("vamos 1")
          calcs.ercQuarterTotals = [q1, q2, q3, q4]
          q1 = 0
          q2 = 0
          q3 = 0
          q4 = 0
          console.log("vamos 2")
          val.qualifiedByQuarter.map((x: any) => {
            if (x['Q1']) {
              q1 += x['Q1']
            };
            if (x['Q2']) {
              q2 += x['Q2']
            };
            if (x['Q3']) {
              q3 += x['Q3']
            };
            if (x['Q4']) {
              q4 += x['Q4']
            }
          })    
          calcs.qualifiedByQuarterTotals = [q1, q2, q3, q4]
          break;
      }

    })
    calcs.wageCalcs = data

    console.log("RETURNING CALCS", calcs)
    return calcs;
  }