/* eslint-disable */
import { useSnackbar } from 'notistack';
import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadWageWorksheet } from '../../store/actions/taxCalculationActions';
import Loading from '../general/Loading';
interface IWageSheetUploader {
  showToast: boolean,
  pppData: any,
  onSuccess: (data: any) => void;
}

const Uploader: React.FC<IWageSheetUploader> = (props) => {
  const dispatch = useDispatch();

  useEffect(() => { }, []);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [refreshIndex, setRefreshIndex] = useState(0)
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const callUpload = (selectedFile: any) => {
    console.log("call the upload", selectedFile)

    const formData = new FormData();
    if (selectedFile) {
   
      formData.append('file', selectedFile);
      formData.append('pppData', JSON.stringify(props.pppData));

      setLoading(true)
      console.log("dispatch the upload", selectedFile)
      dispatch(
        uploadWageWorksheet(
          formData,
          (data) => {
            console.log("IT TRIGGERS SUCCESS")
            setLoading(false);
            if (props.showToast) {
              enqueueSnackbar(
                `Wage worksheet processed`,
                { variant: "success" }
              )
            }
            props.onSuccess(data)
            console.log("FINISHED onSucces")
            setSelectedFile(undefined)
            setRefreshIndex(refreshIndex+1)
          },
          () => {
            console.log("IT TRIGGERS FAIL")
            setLoading(false);
            enqueueSnackbar(
              `Wage sheet upload failed.`,
              { variant: "error" }
            );
          },
        )
      );
    }


  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (selectedFile) {
        callUpload(selectedFile);
      }
    } catch (err) {
      console.log('error is', err);
    }
  };

  return (
    <div key={refreshIndex}>
      <Loading loading={loading} />
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col items-center mt-2">
          <label
            htmlFor="picture"
            className="px-5 py-1 mt-6 bg-white border rounded-lg shadow cursor-pointer hover:bg-purple-600 hover:text-white"
          >
            <span className="mt-2 text-base leading-normal">{selectedFile?.name || 'File Input'}</span>
            <input
              type="file"
              accept="*"
              id="picture"
              name="picture"
              className="hidden"
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  setSelectedFile(e.target.files[0]);
                }
              }}
            />
          </label>
          <button type="submit" className="px-1 py-2 my-6 border-2 border-green-400 rounded-md hover:bg-purple-200">
            Upload wage sheet
          </button>
        </div>
      </form>
    </div>


  );
};

export default Uploader;
