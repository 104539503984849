import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Button,
  Card,
  TextField,
  Typography,
  CardContent,
} from "@mui/material";
import Loading from "../../../components/general/Loading";
import { requestResetPassword } from "../../../store/actions/authActions";
import { useNavigate  } from "react-router-dom";
interface IForgotPasswordProps {}

const ForgotPassword: React.FC<IForgotPasswordProps> = () => {

  const history = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [processing, setProcessing] = useState(false);
//   const recaptchaRef = useRef<any>(null);

  const handleCaptchaChange = (value?: string | null) => {
    setCaptcha(value as string);
  };

  const handleCompletion = () => {
    setProcessing(false);
    enqueueSnackbar(
      "An email was sent with password reset instructions",
      { variant: "success" }
    );
    history('/ResetPasswordNextStep')
  }

  const onSubmit = () => {
    if (captcha && email){
      setProcessing(true);
      dispatch(
        requestResetPassword(
          { email, captcha },
          () => {
            handleCompletion()
          },
          () => {
            enqueueSnackbar(
              "There was a problem requesting a password reset. Confirm you entered the correct email",
              { variant: "error" }
            );
            setProcessing(false);
            history('/')
          }
        )
      );
    }
    else{
      enqueueSnackbar(
        "Email and captcha are required",
        { variant: "error" }
      );
    }

  };

  return (
    <>
      <Loading loading={processing} />
      <Card style={{ width: 400 }}>
        <CardContent style={{ display: "flex", flexDirection: "column" }}>
          <Typography color="textSecondary" gutterBottom variant="h5">
            Forgot Password
          </Typography>
          <TextField
            label="Email"
            fullWidth={true}
            size="small"
            onChange={(event) => setEmail(event?.target.value)}
            style={{ marginBottom: "2rem", marginTop: "2rem" }}
            value={email}
            variant="outlined"
          />
          <ReCAPTCHA
            onChange={(event) => handleCaptchaChange(event)}
			// @ts-ignore
            // ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
          />
          <Button
            onClick={() => onSubmit()}
            style={{ marginTop: "2rem" }}
            variant="outlined"
          >
            Submit
          </Button>
        </CardContent>
      </Card>
    </>

  );
};

export default ForgotPassword;
