import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { tableIcons } from "../shared/MaterialTableShared";
import MaterialTable from "@material-table/core";
import { Accordion, AccordionDetails, AccordionSummary, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
interface IWageSheetTablesProps {
  initialValues: any
  pppData: any
  calcs: any
  ercCalcs: any
  year: number
}
const areEqual = (prevProps: any, nextProps: any) => true;
export const WageSheetTables = React.memo((props: IWageSheetTablesProps) => {

  const cellStyle = {
    width: 300,
    maxWidth: 300
  }


  const quarterColumns = [
    {
      title: 'Name',
      field: 'name',
      sorting: true,
      cellStyle
    },
    {
      title: 'Q1',
      field: 'Q1',
      sorting: true,
      cellStyle
    },
    {
      title: 'Q2',
      field: 'Q2',
      sorting: true,
      cellStyle
    },
    {
      title: 'Q3',
      field: 'Q3',
      sorting: true,
      cellStyle
    },
    {
      title: 'Q4',
      field: 'Q4',
      sorting: true,
      cellStyle
    }
  ]

  const getLine26 = (qualified_total: any, quarterStr: any, year: any) => {
    return (qualified_total * .5) -  props.ercCalcs[year+"_"+quarterStr].fica_50_percent
  }

  return <div>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3a-content"
        id="panel3a-header"
      >
          <Typography variant="h6" gutterBottom component="div">Worksheet Calculations {props.year}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ overflowX: 'scroll' }}>
          {props.calcs.employeeData.length > 0 && <MaterialTable
            icons={tableIcons}
            title={'Wage Inputs (Worksheet)'}
            columns={props.calcs.dynamicWeekColumns}
            style={{ overflowX: 'scroll' }}
            data={props.calcs.employeeData}
            options={{
              sorting: true,
              search: true,
              pageSize: 5,
              pageSizeOptions: [
                5, 10, 50, 100, 200
              ]
            }}
          />}
          {props.calcs.lessPPPData.length > 0 && <MaterialTable
            icons={tableIcons}
            title={'Less PPP (Calculations)'}
            columns={props.calcs.dynamicWeekColumns}
            style={{ overflowX: 'scroll' }}
            data={props.calcs.lessPPPData}
            options={{
              sorting: true,
              search: true,
              pageSize: 5,
              pageSizeOptions: [
                5, 10, 50, 100, 200
              ]
            }}
          />}
          {props.calcs.eligableWageData.length > 0 && <MaterialTable
            icons={tableIcons}
            title={'Eligable Wage (Calculations)'}
            columns={props.calcs.dynamicWeekColumns}
            style={{ overflowX: 'scroll' }}
            data={props.calcs.eligableWageData}
            options={{
              sorting: true,
              search: true,
              pageSize: 5,
              pageSizeOptions: [
                5, 10, 50, 100, 200
              ]
            }}
          />}

          {props.calcs.eligableByQuarter.length > 0 && <MaterialTable
            icons={tableIcons}
            title={'Eligable by Quarter'}
            columns={quarterColumns}
            style={{ overflowX: 'scroll' }}
            data={props.calcs.eligableByQuarter}
            options={{
              sorting: true,
              search: true,
              pageSize: 5,
              pageSizeOptions: [
                5, 10, 50, 100, 200
              ]
            }}
          />}
          {props.calcs.qualifiedByQuarter.length > 0 && <MaterialTable
            icons={tableIcons}
            title={'Qualified by Quarter'}
            columns={quarterColumns}
            style={{ overflowX: 'scroll' }}
            data={props.calcs.qualifiedByQuarter}
            options={{
              sorting: true,
              search: true,
              pageSize: 5,
              pageSizeOptions: [
                5, 10, 50, 100, 200
              ]
            }}
          />}
          {props.calcs.ercByQuarter.length > 0 && <MaterialTable
            icons={tableIcons}
            title={'ERC by Quarter'}
            columns={quarterColumns}
            style={{ overflowX: 'scroll' }}
            data={props.calcs.ercByQuarter}
            options={{
              sorting: true,
              search: true,
              pageSize: 5,
              pageSizeOptions: [
                5, 10, 50, 100, 200
              ]
            }}
          />}
        </div>
      </AccordionDetails>
    </Accordion>
    <br /><br />
    {/* 
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <b>2020 Summary Calculations</b>
          <TableBody>
            <>
              <TableRow>
                <TableCell component="th" scope="row">
                  Quarter
                </TableCell>
                {ercQuarterTotals.map((ercQuarter: any, index: number) => {
                  return <TableCell component="th" scope="row">
                    <b>Q{index + 1}</b>
                  </TableCell>
                })}
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                Line 26
                </TableCell>
                {qualifiedByQuarterTotals.map((qQuarter: any, index: number) => {
                  return <TableCell component="th" scope="row">
                   <b>${Number(qQuarter).toFixed(2)}</b>
                  </TableCell>
                })}
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  ERC
                </TableCell>
                {ercQuarterTotals.map((ercQuarter: any, index: number) => {
                  return <TableCell component="th" scope="row">
                    <b>${Number(ercQuarter).toFixed(2)}</b>
                  </TableCell>
                })}

              </TableRow>
            </>
          </TableBody>
        </Table>
      </TableContainer> */}

      
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <b>{props.year} Summary Calculations</b>
        <TableBody>
          <>
            <TableRow>
              <TableCell component="th" scope="row">
                Quarter
              </TableCell>
              {props.calcs.ercQuarterTotals?.map((ercQuarter: any, index: number) => {
                return <TableCell component="th" scope="row">
                  <b>Q{index + 1}</b>
                </TableCell>
              })}
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Line 26
              </TableCell>
              {props.calcs.qualifiedByQuarterTotals?.map((qQuarter: any, index: number) => {
                
                return <TableCell component="th" scope="row">
                  <b>${Number(getLine26(qQuarter, "q"+(index+1), props.year)).toFixed(2)}</b>
                  {/* <b>${Number(qQuarter).toFixed(2)}</b> */}
                </TableCell>
              })}
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                ERC
              </TableCell>
              {props.calcs.ercQuarterTotals?.map((ercQuarter: any, index: number) => {
                return <TableCell component="th" scope="row">
                  <b>${Number(ercQuarter).toFixed(2)}</b>
                </TableCell>
              })}

            </TableRow>
          </>
        </TableBody>
      </Table>
    </TableContainer>

  </div>
}, areEqual)

