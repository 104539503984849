import { ACTIONS } from "../../enums/actions";

export const login = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
  type: ACTIONS.LOGIN,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const logout = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
  type: ACTIONS.LOGOUT,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const requestResetPassword = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
  type: ACTIONS.REQUEST_PASSWORD_RESET,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const resetPassword = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
  type: ACTIONS.PASSWORD_RESET,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});
 
export const getUserRolesByUser = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
  type: ACTIONS.GET_USER_ROLES,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const confirmEmail = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
  type: ACTIONS.CONFIRM_EMAIL,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});