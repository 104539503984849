import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { IRootState } from './interfaces/store';
import AppAuthenticated from './AppAuthenticated';
import AppPublic from './AppPublic';
import './App.css';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import 'bootstrap/dist/css/bootstrap.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';

import ErrorBoundary from './components/shared/ErrorBoundary';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import "./compiled.css";

function App(props: any) {

	const email = useSelector(
		(state: IRootState) => state.main.user?.email
	);

	return (
		<ErrorBoundary>
		<div key={email}>
			{email ? <AppAuthenticated /> : <AppPublic />}
		</div>
		</ErrorBoundary>
	);
}

App.propTypes = {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

export default App;