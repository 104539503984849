
import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Chip, FormControl, MenuItem, Select, TextField } from "@mui/material";
import { MARGIN_MEDIUM } from "../../enums/layout-enums";
import { safeBool } from "../../shared/utils/gen-utils";
import { dateFormatted } from "../../utils/date-utils";
interface IPPPQuestions {
    qualified2020: boolean
    qualified2021: boolean
    onPPPDataChange: (data: any) => void
}

const PPPQuestions: React.FC<IPPPQuestions> = (props) => {

    const [took2020PPP, setTook2020PPP] = useState(false);
    const [start2020, setStart2020] = useState(dateFormatted(new Date(2020, 0, 1), 'yyyymmdd', '-'));
    const [end2020, setEnd2020] = useState(dateFormatted(new Date(2020, 3, 1), 'yyyymmdd', '-'));
    const [amount2020, setamount2020] = useState(0)
    const [familyMembers2020, setFamilyMembers2020] = useState<string[]>([])

    const [took2021PPP, setTook2021PPP] = useState(false);
    const [start2021, setStart2021] = useState(dateFormatted(new Date(2021, 0, 1), 'yyyymmdd', '-'));
    const [end2021, setEnd2021] = useState(dateFormatted(new Date(2021, 3, 1), 'yyyymmdd', '-'));
    const [amount2021, setamount2021] = useState(0)
    const [familyMembers2021, setFamilyMembers2021] = useState<string[]>([])

    const handlePPPDataChange = () => {
        props.onPPPDataChange({
            took2020PPP,
            start2020,
            end2020,
            amount2020,
            took2021PPP,
            start2021,
            end2021,
            amount2021,
            familyMembers2020,
            familyMembers2021
        })
    }

    useEffect(() => { 
        handlePPPDataChange();
    }, [took2020PPP, start2020, end2020, amount2020, took2021PPP, start2021, end2021, amount2021, familyMembers2020, familyMembers2021])

    return (
        <div>
            {props.qualified2020 && <Box sx={{ padding: "15px", width: "100%" }}>
                <FormControl fullWidth>
                    <div>Did you take 2020 PPP</div>
                    <Select
                        value={took2020PPP}
                        label="Took 2020 PPP"
                        onChange={(e) => {
                            setTook2020PPP(safeBool(e.target.value));
                        }}
                    >
                        <MenuItem value="true">True</MenuItem>
                        <MenuItem value="false">False</MenuItem>
                    </Select>
                </FormControl>
                {took2020PPP && <div>
                    <FormControl fullWidth style={{ marginTop: MARGIN_MEDIUM }}>
                        <TextField
                            sx={{ width: '145px', marginRight: '16px' }}
                            id="start"
                            label="Start"
                            type="date"
                            name="startDate2020"
                            onChange={(e) => {
                                console.log("setStart2020", e.target.value)
                                setStart2020(e.target.value)
                            }
                            }
                            value={start2020}
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth style={{ marginTop: MARGIN_MEDIUM }}>
                        <TextField
                            sx={{ width: '145px', marginRight: '16px' }}
                            id="end"
                            label="End"
                            type="date"
                            name="endDate2020"
                            onChange={(e) => {
                                console.log("setStart2020", e.target.value)
                                setEnd2020(e.target.value)
                            }
                            }
                            value={end2020}
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth style={{ marginTop: MARGIN_MEDIUM }}>
                        <TextField
                            sx={{ width: '145px', marginRight: '16px' }}
                            id="amount2020"
                            label="Amount"
                            type="number"
                            name="amount2020"
                            onChange={(e) => {
                                setamount2020(Number(e.target.value))
                            }
                            }
                            value={amount2020}
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth style={{ marginTop: MARGIN_MEDIUM }}>
                    <Autocomplete
                        multiple
                        id="tags-filled"
                        options={[]}
                        defaultValue={[]}
                        freeSolo
                        onChange={(e:any, newVal: any) => {
                            setFamilyMembers2020(newVal)
                        }}
                        onBlur={((e:any) => {console.log("e", e.target.value)})}
                        renderTags={(value: readonly string[], getTagProps) =>
                            value.map((option: string, index: number) => (
                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="filled"
                                label="Family members on 2020 payroll (enter name and hit return)"
                                placeholder="Name as shown on import"
                            />
                        )}
                    />
                    </FormControl>
                    
                </div>}
            </Box>}

            {props.qualified2021 && <Box sx={{ padding: "15px", width: "100%" }}>
                <FormControl fullWidth>
                    <div>Did you take 2021 PPP</div>
                    <Select
                        value={took2021PPP}
                        label="Took 2021 PPP"
                        onChange={(e) => {
                            setTook2021PPP(safeBool(e.target.value));
                        }}
                    >
                        <MenuItem value="true">True</MenuItem>
                        <MenuItem value="false">False</MenuItem>
                    </Select>
                </FormControl>

                {took2021PPP && <div>
                    <FormControl fullWidth style={{ marginTop: MARGIN_MEDIUM }}>
                        <TextField
                            sx={{ width: '145px', marginRight: '16px' }}
                            id="start"
                            label="Start"
                            type="date"
                            name="startDate2021"
                            onChange={(e) => {
                                setStart2021(e.target.value)
                            }
                            }
                            value={start2021}
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth style={{ marginTop: MARGIN_MEDIUM }}>
                        <TextField
                            sx={{ width: '145px', marginRight: '16px' }}
                            id="end"
                            label="End"
                            type="date"
                            name="endDate2021"
                            onChange={(e) => {
                                setEnd2021(e.target.value)
                            }
                            }
                            value={end2021}
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth style={{ marginTop: MARGIN_MEDIUM }}>
                        <TextField
                            sx={{ width: '145px', marginRight: '16px' }}
                            id="amount2021"
                            label="Amount"
                            type="number"
                            name="amount2021"
                            onChange={(e) => {
                                setamount2021(Number(e.target.value))
                            }
                            }
                            value={amount2021}
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                    <Autocomplete
                        multiple
                        id="tags-filled"
                        options={[]}
                        defaultValue={[]}
                        freeSolo
                        onChange={(e:any, newVal: any) => {
                            console.log("setting", newVal)
                            setFamilyMembers2021(newVal)
                        }}
                        onBlur={((e:any) => {console.log("e", e.target.value)})}
                        renderTags={(value: readonly string[], getTagProps) =>
                            value.map((option: string, index: number) => (
                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="filled"
                                label="Family members on 2021 payroll (enter name and hit return)"
                                placeholder="Name as shown on import"
                            />
                        )}
                    />
                </div>}

            </Box>}
        </div>

    );
};

export default PPPQuestions;



