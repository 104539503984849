export const genericAction = (actionName: string) => {
  return (payload: any, onSuccess?: (...args: any) => void, onFail?: (...args: any) => void, autoSuccessMessage?: string, autoHandleError?: boolean, autoHandleLoading?: boolean) => ({
    type: actionName,
    payload,
    onSuccess: onSuccess || null,
    onFail: onFail || null,
    autoSuccessMessage,
    autoHandleError,
    autoHandleLoading
  }
  )
};


