/* eslint-disable */
import { useSnackbar } from 'notistack';
import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FILE_CHUNK_SIZE, RESOURCE_TYPE } from '../../shared/constants/resource.constants';
import { createUploadResource, uploadResourceFile } from '../../store/actions/resourceActions';
import { getUploadUrls } from '../../store/actions/resourceActions'
import { getFileExtension, getFileType, stripedName } from '../../utils/file';
import Loading from '../general/Loading';
interface IUploader {
  showToast: boolean
}

const Uploader: React.FC<IUploader> = (props) => {
  const dispatch = useDispatch();

  useEffect(() => { }, []);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [uploadType, setUploadType] = useState('wallet')
  const [memberId, setMemberId] = useState(1)
  const [categoryId, setCategoryId] = useState(1)
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const callUpload = (selectedFile: any) => {
    console.log("call the upload", selectedFile)

    let extension = getFileExtension(selectedFile.name)
    let name = stripedName(selectedFile.name);
    let uploadModel = uploadType;
    if (uploadType === 'profile') {
      name = 'profile'
      uploadModel = 'member'
    }

    const parts = Math.ceil(selectedFile.size / FILE_CHUNK_SIZE)

    if (parts < 10) {
      dispatch(
        getUploadUrls(
          {
            "name": selectedFile.name,
            "parts": parts
          },
          (data: any) => {
            console.log('file has been uploaded', data);
          },
          () => {
            console.log('file failed uploading');
          },
          (percent) => {
            console.log(`Upload has completed ${percent}%`);
          }
        )
      )
    }

    setLoading(true);

    const uploadData = {
      name: name,
      model: uploadModel,
      model_id: memberId,
      member_id: memberId,
      category_id: categoryId,
      extension: extension,
      type: 'image',
      parts: parts,
      file: selectedFile
    }

    console.log('uploadData', uploadData)
    
    dispatch(
      uploadResourceFile(
        uploadData,
        () => {
          console.log('file has been uploaded');
          setLoading(false);
          if (props.showToast) {
            enqueueSnackbar(
              `File uploaded`,
              { variant: "success" }
            )
          }
        },
        () => {
          console.log('file failed uploading');
          setLoading(false);
          enqueueSnackbar(
            `File upload failed.`,
            { variant: "error" }
          );
        },
        // (percent: any) => {
        //   console.log(`Upload has completed ${percent}%`);
        // }
      )
    );

  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (selectedFile) {
        //console.log("uploadType", uploadType);
        //console.log("memberId", memberId);
        callUpload(selectedFile);
        // dispatch(uploadSongFile({ file: selectedFile, name: 'song' }));
      }
    } catch (err) {
      console.log('error is', err);
    }
  };

  return (
    <div>
      <Loading loading={loading} />
      <form onSubmit={handleSubmit}>
        <label>Member Id</label>
        <input name="member_id" type="number" value={memberId} onChange={(e) => setMemberId(Number(e.target.value))}></input><br />
        <label>Category Id</label>
        <input name="category_id" type="number" value={categoryId} onChange={(e) => setCategoryId(Number(e.target.value))}></input>
        <br />
        <label>Upload Type</label>
        <select name="upload_type" value={uploadType} onChange={(e) => setUploadType(e.target.value)}>
          <option value="wallet">Wallet</option>
          <option value="profile">Profile</option>
        </select>
        <br />
        <div className="flex flex-col items-center mt-2">
          <label
            htmlFor="picture"
            className="px-5 py-1 mt-6 bg-white border rounded-lg shadow cursor-pointer hover:bg-purple-600 hover:text-white"
          >
            <span className="mt-2 text-base leading-normal">{selectedFile?.name || 'File Input'}</span>
            <input
              type="file"
              accept="*"
              id="picture"
              name="picture"
              className="hidden"
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  setSelectedFile(e.target.files[0]);
                }
              }}
            />
          </label>
          <button type="submit" className="px-1 py-2 my-6 border-2 border-green-400 rounded-md hover:bg-purple-200">
            Upload to s3
          </button>
        </div>
      </form>
    </div>


  );
};

export default Uploader;
