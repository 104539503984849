/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../compiled.css';

const TopBar: React.FC = () => {

  const navigate = useNavigate();

  return (
    <section id="CP_TopBar" className="w-100">
      <div className="container-fluid">
        <div className="row px-0 align-items-center text-center text-lg-start">
          <div className="col me-auto" id="menu">
            <ul className="" id="CP_SmartMenu" data-smartmenus-id="16666817822213943">
              <li id="page_1327" className="">
                {' '}
                <a style={{cursor: 'pointer'}} onClick={ () => navigate('/')} target="_top">
                  Home
                </a>
              </li>
              <li id="page_1214">
              <a style={{cursor: 'pointer'}} onClick={ () => navigate('/about')}
                  target="_top"
                  className="has-submenu"
                  id="sm-16666817822213943-1"
                  aria-haspopup="true"
                  aria-controls="sm-16666817822213943-2"
                  aria-expanded="false"
                >
                  About <span className="sub-arrow"></span>
                </a>
              </li>{' '}
              <li id="page_1214">
              <a style={{cursor: 'pointer'}} onClick={ () => navigate('/ourteam')}
                  target="_top"
                  className="has-submenu"
                  id="sm-16666817822213943-1"
                  aria-haspopup="true"
                  aria-controls="sm-16666817822213943-2"
                  aria-expanded="false"
                >
                  Our Team <span className="sub-arrow"></span>
                </a>
              </li>{' '}
              <li id="page_444">
              <a style={{cursor: 'pointer'}} onClick={ () => navigate('/contact')} target="_top">
                  Contact
                </a>{' '}
              </li>
            </ul>
          </div>
          <a
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#modalMenu"
            id="Toggle"
            className="col col-lg-auto ms-lg-auto"
            title="Menu"
          >
            <i className="fa fa-navicon px-sm-1"></i>
            <span className="d-none d-sm-block d-md-inline text-nowrap">Menu</span>
          </a>

          <a href="tel:(214) 305-9104" className="d-lg-none d-xl-none col col-lg-auto">
            <i className="fa fa-phone px-sm-1"></i>
            <span className="d-none d-sm-block d-md-inline text-nowrap">(214) 305-9104</span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default TopBar;
