/* eslint-disable no-param-reassign */
import _ from "lodash";
import { IReduceAction } from "../../interfaces/store";
import { IMainState } from "../../interfaces/store/main";
import { autoReduce } from "./shared";
import { normalize } from "normalizr";
import { roleSchema, userRoleSchema } from "../sagas/schema";
import { IUser } from "../../shared/interfaces/model/user.interface";
import { IRole } from "../../shared/interfaces/model/role.interface";
import { IUserRole } from "../../shared/interfaces/model/user_role.interface";
import { IUserNote } from "../../shared/interfaces/model/user-note.interface";
import { IQuestion } from "../../shared/interfaces/model/question.interface";
import { IQuestionSet } from "../../shared/interfaces/model/question-set.interface";
import { IDataCollection } from "../../shared/interfaces/model/data-collection.interface";


let setUser = {} as any;
let setUserRoles = {} as any;
let setRoles = {} as any;
let existingUser = localStorage.getItem("user");
let existingRoles = localStorage.getItem("roles");
let existingUserRoles = localStorage.getItem("user_roles")
let existingTimezone = localStorage.getItem("timezone");

if(!existingTimezone) {
  localStorage.setItem("timezone", "America/Chicago")
  existingTimezone = "America/Chicago"
}

// console.log("local storage user", existingUser);
if (existingUser)
  try {
    existingUser = JSON.parse(existingUser);
    setUser = existingUser;
  } catch (e) {}

if(existingUserRoles){
	try{
		existingUserRoles = JSON.parse(existingUserRoles)
		setUserRoles = existingUserRoles
	}
	catch(e){}
}

if(existingRoles){
	try{
		existingRoles = JSON.parse(existingRoles)
		setRoles = existingRoles
	}
	catch(e){}
}

export const mainStateObject = {
  client_options: {},
  jwt_token: '',
  timezone: existingTimezone as string,
  roles: setRoles as { [key: string]: IRole },
  user: setUser as IUser,
  users: {} as { [key: string]: IUser },
  user_roles: setUserRoles as { [key: string]: IUserRole},
  user_notes: {} as IUserNote[],
  dashboard_data: {},
  force_state_refresh: 0,
  question_sets: {}  as { [key: string]: IQuestionSet },
  questions: {}  as { [key: string]: IQuestion },
  data_collections: {}  as { [key: string]: IDataCollection }
};

const resetState = _.cloneDeep(mainStateObject)

const initialState: IMainState = { ...mainStateObject };

const mainReducer = (
  state: any = initialState,
  action: IReduceAction
): IMainState => {

  
  if (action && (action.stateKey === "main" || !action.stateKey)) {
    if (action.normalized || action.removeKeys || action.overrides) {
      return autoReduce(action, state);
    }
    switch (action.type) {
      case "GET_USER_NOTE_SUCCESS":
        state.user_notes = action.payload;
        break;
        
      case "LOGIN_SUCCESS":
		  console.log("login success?", action.data)
		let roles = normalize(action.data.roles, [roleSchema])?.entities?.roles
		let user_roles = normalize(action.data.user_roles, [userRoleSchema])?.entities?.user_roles
        localStorage.setItem("jwt", action.data.token);
        localStorage.setItem(
          "user",
          JSON.stringify(action.data.user)
        );
		localStorage.setItem(
			"user_roles",
			JSON.stringify(user_roles)
		);
		localStorage.setItem(
			"roles",
			JSON.stringify(roles)
		);
        state.user = action.data.user;
		state.user_roles = user_roles
        state.jwt_token = action.data.token;
		state.roles = roles;
		return {...state}
      case "LOGOUT_SUCCESS":
        localStorage.removeItem("jwt");
        localStorage.removeItem("user");
		localStorage.removeItem("user_roles");
		localStorage.removeItem("roles");
        state.jwt_token = "";
        // eslint-disable-next-line no-restricted-globals
        window.location.href = "/Admin";
        return {...resetState}
      case "SET_CLIENT_OPTIONS_SUCCESS":
        state.client_options = {
          ...state.client_options,
          ...action.payload,
        };
        break;
      case "UPDATE_TIMEZONE":
        state.timezone = action.payload
        break;
      default:
        return state;
    }
  }
  return state;
};

export default mainReducer;
