export const zeroPad = (num: any, places: any) => String(num).padStart(places, '0');

//'America/Chicago'
// export const getTimeZonedParts = (dt: Date, timezone: string) => {
// 	return {
// 		yyyy: Number(formatInTimeZone(dt, timezone, 'yyyy')),
// 		MM: Number(formatInTimeZone(dt, timezone, 'MM')),
// 		dd: Number(formatInTimeZone(dt, timezone, 'dd')),
// 		HH: Number(formatInTimeZone(dt, timezone, 'HH')),
// 		mm: Number(formatInTimeZone(dt, timezone, 'mm'))
// 	}
// }

export const getUTCParts = (dt: Date) => {
  return {
    yyyy: dt.getUTCFullYear(),
    m: dt.getUTCMonth(),
    dd: dt.getUTCDate(),
    hh: dt.getUTCHours(),
    mm: dt.getUTCMinutes()
  };
};

export const dateFormatted = (date: any, format: string, seperator = '/') => {
  try {
    const d = new Date(date),
      year = d.getFullYear(),
      hour = d.getHours(),
      minute = d.getMinutes(),
      second = d.getSeconds();

    let month = '' + (d.getMonth() + 1),
      day = '' + d.getDate();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    switch (format) {
      case 'yyyymmdd':
        return [year, month, day].join(seperator);
      case 'yyyymmddhh':
        return [year, month, day].join(seperator) + ' ' + zeroPad(hour, 2) + ':00:00';
      case 'yyyymmddhhmmss':
        return (
          [year, month, day].join(seperator) +
          ' ' +
          zeroPad(hour, 2) +
          ':' +
          zeroPad(minute, 2) +
          ':' +
          zeroPad(second, 2)
        );
      case 'mmddyyyy':
      default:
        return [month, day, year].join(seperator);
    }
  } catch (e) {
    return '';
  }
};

export const mmddyyyySort = (a: any, b: any) => {
  if (a && b) {
    const aDate = new Date(a);
    const bDate = new Date(b);
    return aDate.getTime() - bDate.getTime();
  } else {
    return 0;
  }
};

export const dateFmt = (dateStr: string | undefined) => {
  try {
    if (!dateStr) {
      return '';
    }
    const dt = new Date(dateStr);
    dt.setMilliseconds(0);
    return dt.toISOString().replace(/T/, ' ').replace(/.000Z/, '');
  } catch (e) {
    return '';
  }
};

export const isValidDate = (date: any) => {
  return new Date(date).toString() !== 'Invalid Date';
};
