import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Login from "./pages/public/auth/LoginPage";
import PhoneLogin from "./pages/public/auth/PhoneLoginPage";
import ForgotPassword from "./pages/public/auth/ForgotPasswordPage";
import ResetPassword from "./pages/public/auth/ResetPasswordPage";
import ResetPasswordNextStep from "./pages/public/auth/ResetPasswordNextStepPage";
import { Route, Routes, useLocation } from "react-router-dom";
import { MARGIN_MEDIUM } from "./enums/layout-enums";
import Privacy from "./pages/public/privacy/PrivacyPage";
import Terms from "./pages/public/terms/Terms";
import VerifyEmail from "./pages/private/site/VerifyEmail";
import FormUpload from "./components/form-recognizer/FormUpload";
import ContactComponent from "./pages/public/landing/components/ContactUs/ContactComponent";
import FooterComponent from "./pages/public/landing/components/Footer/FooterComponent";
import HeaderComponent from "./pages/public/landing/components/Header/HeaderComponent";
import SlideComponent from "./pages/public/landing/components/Slider/SlideComponent";
import TopBar from "./pages/public/landing/components/TopBar/TopBarComponent";
import HeroComponent from "./pages/public/landing/components/Hero/HeroComponent";
import TaxCalculationContainer from './components/data-collection/TaxCalculationContainer'
import Home from "./pages/public/landing/Home/Home";
import OurTeamPage from "./pages/public/landing/OurTeam/OurTeamPage";
import AboutPage from "./pages/public/landing/About/AboutPage";
import { ContactPage } from "@mui/icons-material";
import "./compiled.css";
import "./theme/tailwind.css";
import { useEffect, useState } from "react";

function AppPublic(props: any) {
  const location = useLocation();
  const [section, setSection] = useState("");
  useEffect(() => {
    console.log("Location changed");
    switch (location.pathname) {
      case "/":
        setSection("Home");
        break;

      case "/ourteam":
        setSection("Our Team");
        break;

      case "/about":
        setSection("About Us");
        break;

      case "/contact":
        setSection("Contact Us");
        break;

      default:
        break;
    }
  }, [location]);
  return (
    <>
      <CssBaseline />
      <>
        <TopBar />
        <HeaderComponent />
        <HeroComponent section={section} />
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <Home />
              </div>
            }
          />
          <Route
            path="/ourTeam"
            element={
              <div>
                <OurTeamPage />
              </div>
            }
          />
          <Route
            path="/about"
            element={
              <div>
                <AboutPage />
              </div>
            }
          />
          <Route
            path="/contact"
            element={
              <div>
                <ContactPage />
              </div>
            }
          />
          <Route
            path="ResetPasswordNextStep"
            element={
              <div>
                <ResetPasswordNextStep />
              </div>
            }
          />
          <Route
            path="PhoneLogin"
            element={
              <div>
                <PhoneLogin />
              </div>
            }
          />
          <Route
            path="Admin"
            element={
              <div>
                <Login />
              </div>
            }
          />
          <Route
            path="ForgotPassword"
            element={
              <div>
                <ForgotPassword />
              </div>
            }
          />
          <Route
            path="ResetPassword"
            element={
              <div>
                <ResetPassword />
              </div>
            }
          />
          <Route
            path="VerifyEmail"
            element={
              <div>
                <VerifyEmail />
              </div>
            }
          />
          <Route
            path="Questions"
            element={
              <div style={{ margin: MARGIN_MEDIUM }}>
                {/* <QuestionsDynamic /> */}
                <TaxCalculationContainer initialValues={{
                  '2019_q1': 0,
                  '2019_q2': 0,
                  '2019_q3': 0,
                  '2019_q4': 0,
                  '2020_q1': 0,
                  '2020_q2': 0,
                  '2020_q3': 0,
                  '2020_q4': 0,
                  '2021_q1': 0,
                  '2021_q2': 0,
                  '2021_q3': 0,
                  '2021_q4': 0,
                }} />
        
              </div>
            }
          />
          <Route
            path="Form"
            element={
              <div>
                <FormUpload />
              </div>
            }
          ></Route>
          <Route path="Privacy" element={<Privacy />} />
          <Route path="Terms" element={<Terms />} />
        </Routes>
        <SlideComponent />
        <ContactComponent />
        <FooterComponent />
      </>
    </>
  );
}

export default AppPublic;
