import { ACTIONS } from "../../enums/actions";

export const getUser = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
	type: ACTIONS.GET_USER,
	payload,
	onSuccess: onSuccess || null,
	onFail: onFail || null
  });

export const getUserNotes = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
  type: ACTIONS.GET_USER_NOTES,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const createUserNote = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
  type: ACTIONS.CREATE_USER_NOTE,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});
  

export const updateUserNote = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
  type: ACTIONS.UPDATE_USER_NOTE,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});


export const deleteUserNote = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
  type: ACTIONS.DELETE_USER_NOTE,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const getUsers = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
  type: ACTIONS.GET_USERS,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const updateUser = (payload: any, onSuccess: Function | null = null, onFail?: (message? : string) => void ) => ({
  type: ACTIONS.UPDATE_USER,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const userActivation = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
  type: ACTIONS.USER_ACTIVATION,
  payload,
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const getDashboardData = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
	type: ACTIONS.GET_DASHBOARD_DATA,
	payload,
	onSuccess: onSuccess || null,
	onFail: onFail || null
  });

  export const downloadUsersReport = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
	type: ACTIONS.DOWNLOAD_USERS_REPORT,
	payload,
	onSuccess: onSuccess || null,
	onFail: onFail || null
  });

  export const addRemoveRole = (payload: any, onSuccess: Function | null = null, onFail: Function | null = null) => ({
	type: ACTIONS.ADD_REMOVE_ROLE,
	payload,
	onSuccess: onSuccess || null,
	onFail: onFail || null
  });