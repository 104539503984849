import React from "react";
import "../compiled.css";

const OurTeamPage: React.FC = () => {
  return (
    <section id="CP_Content">
      <div className="container-fluid py-4">
        <div id="CP_Breadcrumbs" className="text-center text-lg-start m-0">
          <a className="text-nowrap">
            <span className="fa fa-home"></span> Home
          </a>
          <a className="text-nowrap">About</a>{" "}
          <span className="text-nowrap">Our Team</span>
        </div>
        <div className="row sm:pr-12 md:pr-12 lg:pr-0 xl:pr-0 2xl:pr-0 pr-12">
          <div className="py-5 col-xxl-7 col-xl-7" id="content">
            <div id="Text" className="text-left">
              <h1 className="text-center">Your Team of ERC Experts</h1>
              <h3 className="text-center">
                A Literal "Dream Team" of Tax and Accounting Experts Working for
                You
              </h3>
            </div>
            <hr />
            <div>
              <h3>
                <img
                  alt=""
                  src="./assets/landing/Cyrus%20Headshot.jpeg"
                  style={{
                    margin: "0px 20px 10px 0px",
                    width: "300px",
                    height: "300px",
                    boxShadow: "1px 1px 3px rgb(0 0 0 / 50%)",
                    borderRadius: "10px",
                  }}
                  className="2xl:float-left xl:float-left lg:float-left md:float-left sm:float-none"
                />
                <br className="2xl:hidden xl:hidden lg:hidden sm:block md:block" />
                Cyrus Amini, Esq., CFA
              </h3>
              <h5>
                <b>Chief Compliance Officer, The ERC Group</b>
              </h5>
              <p>
                Cyrus leads our compliance efforts and heads the ERC compliance
                and qualification team. He is also a member of the Risk
                Committee at The ERC Group. He is widely recognized as a thought
                leader in the financial services industry.
              </p>
              <p>
                Cyrus received a B.A. in Economics from The Wharton School of
                the University of Pennsylvania, and a J.D. from Boston College
                Law School. Cyrus is a Member of the New York State Bar
                Association, and a CFA Charterholder.
              </p>
              <p>
                When Cyrus isn't busy solving the World's problems, he is also a
                devoted husband and father and enjoys rugby and travel. Cyrus
                calls Charleston, South Carolina home.
              </p>
              <p></p>
              <p></p>
              <p></p>
              <p></p>
              <p></p>
              <p></p>
              <p></p>
              <p></p>
            </div>
            <hr />
            <h3>
              <img
                alt=""
                src="./assets/landing/Justin%20Dean.png"
                style={{
                  margin: "0px 20px 10px 0px",
                  width: "300px",
                  height: "300px",
                  boxShadow: "1px 1px 3px rgb(0 0 0 / 50%)",
                  borderRadius: "10px",
                }}
                className="2xl:float-left xl:float-left lg:float-left md:float-left sm:float-none"
              />
              <br className="2xl:hidden xl:hidden lg:hidden sm:block md:block" />
              Justin Dean
            </h3>
            <h5>
              <strong>Service and Operations Lead, The ERC Group</strong>
            </h5>
            <p>Justin leads our client service and operational efforts.</p>
            <p>Justin calls Surprise, Arizona home.</p>
            <hr />
            <br style={{ clear: "both" }} />
            <div>
              <img
                alt="Our Team The ERC Group, Accounting"
                src="./assets/landing/EricP-portrait.png"
                style={{
                  margin: "0px 20px 10px 0px",
                  width: "300px",
                  height: "300px",
                  boxShadow: "1px 1px 3px rgb(0 0 0 / 50%)",
                  borderRadius: "10px",
                }}
                className="2xl:float-left xl:float-left lg:float-left md:float-left sm:float-none"
              />
              <br className="2xl:hidden xl:hidden lg:hidden sm:block md:block" />
              <h3>Eric Pierre, CPA</h3>
              <h5>
                <strong>Founder/CEO, Pierre Accounting</strong>
              </h5>
              <p>
                <a
                  href="https://www.linkedin.com/in/pierreaccounting/"
                  target="_blank"
                >
                  LinkedIn
                </a>
              </p>
              <p>
                Eric is the founder, CEO, Owner, and Principal of Pierre
                Accounting with offices in Austin, Los Angeles, and San Diego.
                Eric has worked in accounting, tax, finance, and audit since
                2003. He specializes in Advanced Tax Strategies, Financial
                Planning and Analysis, Mergers and Acquisitions, Real Estate,
                and Forensic Auditing for clients.
              </p>
              <p>
                Prior to starting Pierre Accounting in 2015, Eric worked for
                Various Fortune 500 companies including Deloitte &amp; Touche,
                McDermott, Abbott Laboratories, and Team Industrial Services.
              </p>
              <p>
                Eric graduated with a Master of Professional Accountancy and a
                Bachelor of Business Administration in Accountancy, with honors,
                from Stephen F. Austin State University. Eric earned his CPA
                Licenses with the Texas and California State Boards of Public
                Accountancy. Eric is also a second-generation CPA.
              </p>
              <p>
                A renowned tax expert with the local and national media, Eric
                has been quoted or mentioned by Forbes, CNBC, Bloomberg Tax,
                Yahoo Finance, Time, and CBS in San Diego.
              </p>
              <p>
                An avid basketball fan, Eric played professionally for the San
                Diego Guardians of the American Basketball Association (ABA). In
                addition, he gives back his time mentoring and serving our youth
                across the country.
              </p>
            </div>
            <br style={{ clear: "both" }} />
            <hr />
            <br style={{ clear: "both" }} />
            <div>
              <img
                alt="Our Team The ERC Group, Accounting"
                src="./assets/landing/SamD-portrait.png"
                style={{
                  margin: "0px 20px 10px 0px",
                  width: "300px",
                  height: "300px",
                  boxShadow: "1px 1px 3px rgb(0 0 0 / 50%)",
                  borderRadius: "10px",
                }}
                className="2xl:float-left xl:float-left lg:float-left md:float-left sm:float-none"
              />
              <br className="2xl:hidden xl:hidden lg:hidden sm:block md:block" />
              <h3>Sam Derakhshan</h3>
              <h5>
                <strong>Principal, Level 8 Management</strong>
              </h5>
              <p></p>
              <p>
                Sam is the founder, CEO, owner and principal of Level 8
                Management Inc. A passionate and highly motivated entrepreneur
                who cares deeply about the clients he works with. With over 15
                years of experience in tax, accounting, finance and business
                management, Sam has been working with many businesses, small and
                large, across multiple industries, focusing on helping them grow
                their business and maximize their profits through advanced tax
                planning, financial visibility and understanding.
              </p>
              <p>
                A Certified Tax Preparer in the state of California. In 2007 he
                received his bachelor’s degree in accounting and finance at
                Cal-State University, Northridge. Sam calls Woodland Hills,
                California home.
              </p>
            </div>
            <br style={{ clear: "both" }} />
            <hr />
            <br style={{ clear: "both" }} />
            <div>
              <img
                alt="Our Team The ERC Group, Accounting"
                src="./assets/landing/HowardM-portrait.png"
                style={{
                  margin: "0px 20px 10px 0px",
                  width: "300px",
                  height: "300px",
                  boxShadow: "1px 1px 3px rgb(0 0 0 / 50%)",
                  borderRadius: "10px",
                }}
                className="2xl:float-left xl:float-left lg:float-left md:float-left sm:float-none"
              />
              <br className="2xl:hidden xl:hidden lg:hidden sm:block md:block" />
              <h3>Howard Morin</h3>
              <h5>
                <strong>Founder/Partner, Helium Financial Group</strong>
              </h5>
              <p>
                <a
                  href="https://www.linkedin.com/in/howardmorin/"
                  target="_blank"
                >
                  LinkedIn
                </a>
              </p>
              <p>
                Howard Morin co-founded Helium Financial Group with partner Gary
                Russell in 2016. Howard has over 23 years of financial services
                and organizational leadership experience. Most recently he
                oversaw the Financial Services Group of Conover serving as CEO
                of Conover Capital Management and Conover Securities.
              </p>
              <p>
                Starting as a manager of the institutional trader business at
                TradeStation Group his success was recognized by the Intel
                Corporation who relocated him to the Pacific Northwest. Later
                named Managing Director of Global Financial Services,
                responsible for implementing technology at some of the largest
                financial institutions in the world.
              </p>
              <p>
                After a career at Intel, Howard joined Union Bank of Switzerland
                (UBS) as a private financial advisor. Howard is passionate about
                advising clients on key decisions and working with employers to
                align their corporate retirement plan with their strategic
                goals. A native of South Florida, after almost 20 years in the
                Seattle area, he now calls Scottsdale, Arizona home.
              </p>
            </div>
            <br style={{ clear: "both" }} />
            <hr />
            <br style={{ clear: "both" }} />
            <div>
              <img
                alt="Our Team The ERC Group, Accounting"
                src="./assets/landing/GaryR-portrait.png"
                style={{
                  margin: "0px 20px 10px 0px",
                  width: "300px",
                  height: "300px",
                  boxShadow: "1px 1px 3px rgb(0 0 0 / 50%)",
                  borderRadius: "10px",
                }}
                className="2xl:float-left xl:float-left lg:float-left md:float-left sm:float-none"
              />
              <br className="2xl:hidden xl:hidden lg:hidden sm:block md:block" />
              <h3>Gary Russell</h3>
              <h5>
                <strong>Founder/Partner, Helium Financial Group</strong>
              </h5>
              <p>
                <a
                  href="https://www.linkedin.com/in/gary-russell-60baaa12/"
                  target="_blank"
                >
                  LinkedIn
                </a>
              </p>
              <p>
                Gary Russell is the co-founder of Helium Financial Group with
                partner Howard Morin. He previously served as Director of Wealth
                Management at Conover Capital Management.
              </p>
              <p>
                He brings over 15 years of Wealth Management, Financial
                Planning, and Retirement Plan Consulting experience to our
                valued clients and trusted partners every day. As a recognized
                industry leader and frequent guest speaker on a variety of
                financial topics, he brings a unique and innovative approach to
                delivering financial solutions for clients.
              </p>
              <p>
                When Gary isn't working with clients, he enjoys the outdoors and
                is an avid pilot. A native of Bainbridge Island, Gary now calls
                the Olympic Peninsula home.
              </p>
            </div>
            <br style={{ clear: "both" }} />
            <hr />
            <br style={{ clear: "both" }} />
            <div>
              <h3>
                <img
                  alt=""
                  src="./assets/landing/Matt%20Jensen.png"
                  style={{
                    margin: "0px 20px 10px 0px",
                    width: "300px",
                    height: "300px",
                    boxShadow: "1px 1px 3px rgb(0 0 0 / 50%)",
                    borderRadius: "10px",
                  }}
                  className="2xl:float-left xl:float-left lg:float-left md:float-left sm:float-none"
                />
                <br className="2xl:hidden xl:hidden lg:hidden sm:block md:block" />
                Matt Jensen
              </h3>
              <h5>
                <strong>Business Development Lead, The ERC Group</strong>
              </h5>
              <p></p>
              <p>
                Matt Jensen brings 20 years of experience in the financial
                services industry having served roles in structured finance,
                private equity and wealth management. Mr Jensen started his
                career in wealth management at Union Bank of Switzerland in Los
                Angeles.
              </p>
              <p>
                A life-long California resident,&nbsp; Mr Jensen graduated with
                honors from the University of San Diego.
              </p>
              <p>
                Mr Jensen currently resides in Orange County California with his
                wife and two children.
                <span style={{ boxSizing: "border-box" }}>&nbsp;</span>
              </p>
              <p></p>
              <p></p>
              <p></p>
              <p></p>
            </div>
            <p></p>
            <hr />
            <h3>
              <img
                alt=""
                src="./assets/landing/Allison%20Headshot_300x400.png"
                style={{
                  margin: "0px 20px 10px 0px",
                  width: "300px",
                  height: "300px",
                  boxShadow: "1px 1px 3px rgb(0 0 0 / 50%)",
                  borderRadius: "10px",
                }}
                className="2xl:float-left xl:float-left lg:float-left md:float-left sm:float-none"
              />
            </h3>
            <br className="2xl:hidden xl:hidden lg:hidden sm:block md:block" />
            <h3>Allison Rogers</h3>
            <h5>
              <strong>Client Services, The ERC Group</strong>
            </h5>
            <p></p>
            <p dir="ltr">
              Pacific Northwest born and bred, Allison Rogers has over 15 years
              of client services and leadership experience.&nbsp;
            </p>
            <p>
              As a business owner herself since 2016, Allison has a passion for
              working with businesses both small and large, fine-tuning customer
              experience and guaranteeing client satisfaction.&nbsp;
            </p>
            <p>
              With a fine attention to detail and a broad vision, she works
              closely with Helium Financial Group to oversee and facilitate the
              completion of hundreds of projects at a time, ensuring the highest
              quality and value for each and every client.&nbsp;
            </p>
            <p>
              She lives with her husband in the Seattle area, where she enjoys
              hiking, gift-giving, and copious amounts of rain and coffee.
            </p>
            <p></p>
            <p></p>
            <hr />
            <h3>
              <img
                alt=""
                src="./assets/landing/Tami%20Todd.jpeg"
                style={{
                  margin: "0px 20px 10px 0px",
                  width: "300px",
                  height: "300px",
                  boxShadow: "1px 1px 3px rgb(0 0 0 / 50%)",
                  borderRadius: "10px",
                }}
                className="2xl:float-left xl:float-left lg:float-left md:float-left sm:float-none"
              />
              <br className="2xl:hidden xl:hidden lg:hidden sm:block md:block" />
              Tami Todd
            </h3>
            <h5>
              <strong>Project Management, The ERC Group</strong>
            </h5>
            <hr />
            <br style={{ clear: "both" }} />
            <div>
              <img
                alt="Our Team The ERC Group, Accounting"
                src="./assets/landing/DavidT-portrait.png"
                style={{
                  margin: "0px 20px 10px 0px",
                  width: "300px",
                  height: "300px",
                  boxShadow: "1px 1px 3px rgb(0 0 0 / 50%)",
                  borderRadius: "10px",
                }}
                className="2xl:float-left xl:float-left lg:float-left md:float-left sm:float-none"
              />{" "}
              <br className="2xl:hidden xl:hidden lg:hidden sm:block md:block" />
              <h3>David Trende</h3>
              <h5>
                <strong>Partner, Wealth Design Partners</strong>
              </h5>
              <p></p>
              <p>
                David graduated from Baldwin Wallace College with a Bachelors of
                Arts, Majoring in Business Administration, concentration in
                Finance, and Minors in Economics and History. Before graduating,
                he was hired by Merrill Lynch as a financial consultant, and he
                secured a Series 7 and 66 securities licenses, as well as Life,
                Health, and Annuity licenses. During that time David built a
                strong financial services practice built around reasonable
                returns from simple investment strategies. In 2001, he expanded
                his practice by joining Legacy Financial, David added strategic
                resources for more advanced financial planning, estate planning
                and other client services.
              </p>
              <p>
                In 2005, together with Ted Rusinoff, David helped build a
                company focused on helping attorneys, CPAs and trust advisors
                review client portfolio holdings, specifically to address the
                fiduciary liability issues related to the beneficiaries. Having
                a full service investment and insurance operation well
                established, David focuses on meeting the needs of his clients,
                as well as those of his strategic alliances with local CPAs,
                attorneys and trust advisors. This focus allows him to provide
                the best possible service and value to all of his professional
                relationships.
              </p>
            </div>
            <p></p>
            <hr />
            <h3>
              <img
                alt=""
                src="./assets/landing/caleb%20weil%20headshot.jpg"
                style={{
                  margin: "0px 20px 10px 0px",
                  width: "300px",
                  height: "300px",
                  boxShadow: "1px 1px 3px rgb(0 0 0 / 50%)",
                  borderRadius: "10px",
                }}
                className="2xl:float-left xl:float-left lg:float-left md:float-left sm:float-none"
              />
              <br className="2xl:hidden xl:hidden lg:hidden sm:block md:block" />
              Caleb Weil, CPA, MST
            </h3>
            <p>
              Caleb is a practicing CPA in Seattle, Washington working with tax
              clients across the nation. Working with small businesses, he
              realized the harm caused by the COVID-19 pandemic and related
              sanctions, and wishes to help small businesses find relief
              wherever possible. Seeing opportunity with the Employee Retention
              Credit, Caleb became an expert in the credit and enjoys helping
              businesses navigate the complex regulatory environment of the
              CARES Act.
            </p>
            <p>
              Caleb received his Bachelor’s degree in accounting from the
              University of Montana, and his Master of Science in Taxation from
              Gonzaga University. He holds his CPA license with the Washington
              State Board of Accountancy.&nbsp;
            </p>
            <p
              className="x_MsoNormal"
              style={{
                margin: "0in",
                fontSize: "11pt",
                fontFamily: "Calibri, sansSerif",
                caretColor: "rgb(0, 0, 0)",
                color: "rgb(0, 0, 0)",
              }}
            ></p>
            <p>
              <br style={{ clear: "both" }} />
            </p>
          </div>
          <div
            className="col-xxl-3 col-xl-3 offset-xl-1  d-none d-xl-block"
            id="CP_Sidebar"
          >
            <section id="CP_Section" className="pt-5">
              <h3>In This Section</h3>
              <ul
                role="group"
                aria-hidden="true"
                aria-labelledby="sm-16667650698319092-1"
                aria-expanded="false"
                className="h5"
              >
                <li>
                  <a href="" target="_top">
                    Our Team
                  </a>{" "}
                </li>
              </ul>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurTeamPage;
