
import * as React from 'react';
import { useNavigate , useParams } from 'react-router-dom';
import { Box, Button, FormControl, FormHelperText, IconButton, Input, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../interfaces/store';
import { COLORS, MARGIN_MEDIUM } from '../../../enums/layout-enums';
import { getUser, updateUser } from '../../../store/actions/userActions';
import { dateFmt, dateFormatted } from '../../../shared/utils/date-utils';
import { CSVLink } from 'react-csv';
import Download from '@mui/icons-material/Download';
import Loading from '../../../components/general/Loading';
import { Close, Edit, SaveAlt } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

const authContStyle = {
    marginTop: 16, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'
}

const iconStyle = {
    color: COLORS.PURPLE_BRAND_PRIMARY, fontSize: 24, cursor: 'pointer'
}
  

function Users(props: any) {

  const params = useParams<{ userId: string }>()
  const dispatch = useDispatch();
  const history = useNavigate();
  const [user, setUser] = useState({} as any)
  const [editedUser, setEditedUser] = useState({} as any)
	const [userFetched, setUserFetched] = useState(false)
	const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [emailError, setEmailError] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {	
		if (!isNaN(Number(params.userId))){
			dispatch(getUser({user_id: params.userId}, () => {
				console.log('setUserFetched true')
				setUserFetched(true)
			}))
		}
  }, [])

	const users = useSelector(
        (state: IRootState) => state.main.users
    );

	useEffect(() => {
		if (userFetched){
			const matchedUser = Object.values(users).find((x) => x.id === Number(params.userId)) as any
			console.log("matchedUser?", matchedUser);
			if (!matchedUser) {
				history('/Users')
			}
			else {
				matchedUser.last_visit = dateFormatted(matchedUser.last_visit, "mmddyyyy")
				const roleNames = [] as any[]
				const pUserRoles = Object.values(userRoles).filter((x) => x.user_id === matchedUser.id);
				pUserRoles.forEach((pUserRole) => {
					const pRole = Object.values(roles).find((x) => x.id === pUserRole.role_id);
					roleNames.push(pRole?.name);
				})
				if (roleNames) {
					matchedUser.roleString = roleNames.join(', ')
				}
				else {
					matchedUser.roleString = 'Customer'
				}
				setUser(matchedUser)
				setEditedUser(matchedUser)
				setLoading(false);
			}
		}


	}, [users, userFetched])


  const roles = useSelector(
      (state: IRootState) => state.main.roles
  );

  const userRoles = useSelector(
      (state: IRootState) => state.main.user_roles
  );

  const validateUserEmail = (email: string) => {
    var re = /\S+@\S+\.\S+/;
    if(!re.test(email)) {
      setEmailError("Incorrect format for Email Address.");
    }
    else {
      setEmailError("");
    }
  }
  const updateEditedUser = (key: string, value: any) => {
    setEditedUser({...editedUser, [key]: value})
  }

	<Loading loading={loading} />

	if (loading){
		return <div></div>
	}
    return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <b>User Info</b>
        <Box>
          {
            !editing ?          
            <IconButton
              style={iconStyle}
              edge="end"
              aria-label="Edit"
              onClick={() => {
                  setEditing(true);
              }}
            >
              <Edit/>
            </IconButton>
            :
            <>
              <IconButton
                  style={iconStyle}
                  edge="end"
                  aria-label="Close"
                  onClick={() => {
                    setEditing(false);
                    setEditedUser(user)
                    setEmailError("");
                    enqueueSnackbar(
                      "Changes Reverted",
                      { variant: "default" }
                    )
                  }}
              >
                  <Close color="disabled"/>
              </IconButton>
              <IconButton
                  style={iconStyle}
                  edge="end"
                  aria-label="Edit"
                  onClick={() => {
                    let updatedUser = {
                      id: editedUser.id,
                      email: editedUser.email,
                      first_name: editedUser.first_name,
                      last_name: editedUser.last_name,
                      phone: editedUser.phone,
                      birthdate: editedUser.birthdate
                    }

                    if(!emailError) {             
                      dispatch(updateUser(updatedUser, () => {
                      setEditing(false);
                      enqueueSnackbar(
                        "User Saved",
                        { variant: "success" }
                      )
                      },
                      (test) => {
                        enqueueSnackbar(
                          test,
				                  { variant: "error" }
                        )
                      }
                    
                    ))
                    }
                    else {
                      enqueueSnackbar(
                        "Improper Email Format",
                        { variant: "error" }
                      )
                    }
      
                    
                  }}

              >
                  <SaveAlt/>
              </IconButton>
            </>
          }

        </Box>
      </Box>


        <hr />
        <br />


            
        <div>First Name: {user?.first_name}</div>
        <div>Last Name: {user?.last_name}</div>
        {!editing ?
        <div>Email: {user?.email}</div>
        :
        <>
          Email: 
          
          <FormControl error={emailError ? true : false} variant="standard">
            <Input
              id="component-error"
              value={editedUser?.email}
              onChange={(e) => {
                validateUserEmail(e.target.value);
                updateEditedUser("email", e.target.value);
              }}
              aria-describedby="component-error-text"
            />
            {emailError?<FormHelperText id="component-error-text">{emailError}</FormHelperText>:null}
          </FormControl>
          
        </>
      
      }
        <div>Phone: {user?.phone}</div>
        <div>Birth Date: {dateFmt(user?.birthdate)}</div>
        <div>Last Visit: {dateFmt(user?.last_visit)}</div>
        {/* <pre>{JSON.stringify(user,null,2)}</pre> */}
        <br />
        <br />

        {/* <pre>{JSON.stringify(userOrders,null,2)}</pre> */}
    </>
    )
}

export default Users
