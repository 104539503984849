import React from "react";
import { IUser } from "../../../shared/interfaces/model/user.interface";


interface IAccountPageProps {
    user : IUser;
}

const AccountPage: React.FC<IAccountPageProps> = (props) => {

    const renderDataRow = (title: string | undefined, text: string | undefined) => {
        return <div>{title}: {text}</div> 
      }


    return  (
        <>
            <h2>Account</h2>
            {renderDataRow('Name', props.user?.first_name + ' ' + props.user?.last_name)}
            {renderDataRow('Email', props.user.email)}
            {renderDataRow('Phone', props.user.phone)}
        </>
    );    
    
}
export default AccountPage;