import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People'
import StoreIcon from '@mui/icons-material/Store';
import DataCollectionIcon from '@mui/icons-material/QuestionMarkTwoTone';
import Toolbar from '@mui/material/Toolbar';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from './interfaces/store';
import { getUserRolesByUser, logout } from './store/actions/authActions';
import { useEffect, useState } from 'react';
import { COLORS } from './enums/layout-enums';
import Users from './pages/private/users/UsersPage';
import UserView from './pages/private/users/UserViewPage';
import Privacy from './pages/public/privacy/PrivacyPage';
import AdminDashboard from './pages/private/dashboard/DashboardPage';
import Account from './pages/private/account/AccountPage';
import DataCollection from './pages/private/data-collection/DataCollection'
import Terms from './pages/public/terms/Terms';
import ForceLogout from './components/shared/ForceLogout';
import ReleaseNotes, { release_notes } from './pages/private/site/ReleaseNotes';
import Uploader from './components/shared/Uploader';
import QuestionSet from './pages/private/data-collection/QuestionSet';
import VerifyEmail from './pages/private/site/VerifyEmail';

const drawerWidth = 240;
const authContStyle = {
	marginTop: 0,
	width: 800,
}

function AppAuthenticated(props: any) {

	const user = useSelector(
		(state: IRootState) => state.main.user
	);

	const user_roles = useSelector(
		(state: IRootState) => state.main.user_roles
	)

	const roles = useSelector(
		(state: IRootState) => state.main.roles
	)

	const clientOptions = useSelector(
		(state: IRootState) => state.main.client_options
	);

	const [isAdmin, setIsAdmin] = useState(false);

	const dispatch = useDispatch();
	const { window } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const history = useNavigate();

	useEffect(() => {
		dispatch(getUserRolesByUser({}))
	}, [])

	useEffect(() => {
		let userRolesArr = Object.values(user_roles);
		let rolesArr = Object.values(roles);
		if (!isAdmin && user?.id && userRolesArr.length && rolesArr.length) {

			const adminRole = rolesArr.find((x) => x.name === "Admin");
			if (adminRole) {
				const userAdminRole = userRolesArr.find((x) => x.user_id === user.id && x.role_id === adminRole.id)
				if (userAdminRole) {
					setIsAdmin(true);
				}
			}

		}
	}, [user, user_roles, roles, isAdmin])



	const drawer = (
		<div>
			<Toolbar />
			<Divider />
			<List>


				{isAdmin && <ListItem button key={'dashboard'}
					onClick={(): void => {
						history('/Dashboard')
					}}
				>
					<ListItemIcon>
						<DashboardIcon />
					</ListItemIcon>
					<ListItemText primary={'Dashboard'} />
				</ListItem>}

				<ListItem button key={'users'}
					onClick={(): void => {
						history('/Users')
					}}
				>
					<ListItemIcon>
						<PeopleIcon />
					</ListItemIcon>
					<ListItemText primary={'Users'} />
				</ListItem>

				<ListItem button key={'account'}
					onClick={(): void => {
						history('/Account')
					}}
				>
					<ListItemIcon>
						<ManageAccountsIcon />
					</ListItemIcon>
					<ListItemText primary={'Account'} />
				</ListItem>

				{isAdmin && <ListItem button key={'data-collection'}
					onClick={(): void => {
						history('/DataCollection')
					}}
				>
					<ListItemIcon>
						<DataCollectionIcon />
					</ListItemIcon>
					<ListItemText primary={'Questionnaires'} />
				</ListItem>}

				{isAdmin && <ListItem button key={'about'}
					onClick={(): void => {
						history('/About')
					}}
				>
					<ListItemIcon>
						<StoreIcon />
					</ListItemIcon>
					<ListItemText primary={'About'} />
				</ListItem>}
			</List>
			<Divider />
			<List>
				<ListItem button key={'logout'}
					onClick={(): void => {
						dispatch(logout({}, () => {
							history('/Admin')
						}));
					}}
				>
					<ListItemIcon>
						<LogoutIcon />
					</ListItemIcon>
					<ListItemText primary={'Log Out'} />
				</ListItem>
			</List>
			{/* <TimeZoneSelector/> */}
		</div>

	);
	const container = window !== undefined ? () => window().document.body : undefined;
	const random = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<AppBar
				style={{ backgroundColor: COLORS.PURPLE_BRAND_PRIMARY }}
				position="fixed"
				sx={{
					width: { sm: `calc(100% - ${drawerWidth}px)` },
					ml: { sm: `${drawerWidth}px` },
				}}
			>
				<Toolbar>
					<div style={{ display: 'flex', width: '100%' }}>
						<div style={{ flex: 1 }}>
							<IconButton
								color="inherit"
								aria-label="open drawer"
								edge="start"
								onClick={handleDrawerToggle}
								sx={{ mr: 2, display: { sm: 'none' } }}
							>
								<MenuIcon />
							</IconButton>
							<img onClick={() => {
						history('/Dashboard')
					}} alt={'logo'} width={150} style={{ cursor: 'pointer', float: 'left' }} src={process.env.PUBLIC_URL + '/logo.png'}></img>

						</div>
						<div onClick={() => {
							history('/ReleaseNotes')
						}} style={{ cursor: 'pointer', fontSize: 12, flex: 1, justifyContent: 'right', textAlign: 'right' }}>
							App Version: {release_notes[0].version}
						</div>
					</div>

				</Toolbar> 
			</AppBar>
			<Box
				component="nav"
				sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
				aria-label="mailbox folders"
			>
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Drawer
					container={container}
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: 'block', sm: 'none' },
						'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
					}}
				>
					{drawer}
				</Drawer>
				<Drawer
					variant="permanent"
					sx={{
						display: { xs: 'none', sm: 'block' },
						'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
					}}
					open
				>
					{drawer}
				</Drawer>
			</Box>
			<Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: 8 }}>
				<Routes>
					<Route path="Dashboard" element={<div style={authContStyle}>
						<AdminDashboard />
					</div>} />
					<Route path="Account" element={<Account user={user} />} />
					<Route path="DataCollection" element={<DataCollection/>} />
					<Route path="QuestionSet/:questionSetId" element={<QuestionSet/>} />
					<Route path="Users/:userId" element={<UserView />} />
					<Route path="Users" element={<Users />} />
					<Route path="ForgotPassword" element={<ForceLogout />} />
					<Route path="ResetPassword" element={<ForceLogout />} />
					<Route path="Privacy" element={<Privacy />} />
					<Route path="Terms" element={<Terms />} />
					<Route path="About" element={<ReleaseNotes />} />
					<Route path="ReleaseNotes" element={<ReleaseNotes />} />
					<Route path="Upload" element={<div style={authContStyle}><Uploader showToast={true} /></div>} />
					<Route path="VerifyEmail" element={<div style={authContStyle}><VerifyEmail /></div>} />	
				</Routes>
			</Box>
		</Box>
	)

}

export default AppAuthenticated