import { normalize, schema } from 'normalizr'
import { put } from 'redux-saga/effects'

export const roleSchema = new schema.Entity('roles')
export const userRoleSchema = new schema.Entity('user_roles')
export const myUserSchema = new schema.Entity('myUserSchema')
export const userSchema = new schema.Entity('user')
export const usersSchema = new schema.Entity('users')
export const userNotesSchema = new schema.Entity('user_notes')
export const resourceSchema = new schema.Entity('resources')
export const questionSchema = new schema.Entity('questions')
export const questionSetSchema = new schema.Entity('question_sets')
export const dataCollectionSchema = new schema.Entity('dataCollectionSchema')

export const dashboardDataSchema = new schema.Entity('dashboard_data', {}, { idAttribute: 'uuid' })

export function autoYields (data: any): any[]{
    const yields = [] as any[]
    const pData = {
      dashboard_data: dashboardDataSchema,
      data_collection: dataCollectionSchema,
      data_collections: [dataCollectionSchema],
      my_user: myUserSchema,
      resource: resourceSchema,
      resources: [resourceSchema],
      role: roleSchema,
      roles: [roleSchema],
      users: usersSchema,
      usersSchema: [usersSchema],
      user_note: userNotesSchema,
      user_notes: [userNotesSchema],
      question: questionSchema,
      questions: [questionSchema],
      question_set: questionSetSchema,
      question_sets: [questionSetSchema],
      user_role: userRoleSchema,
      user_roles: [userRoleSchema],
    } as any

    Object.keys(data).forEach((property: string) => {
      if (pData[property]) {
        yields.push(put({ type: 'REDUCE_' + property + '_SUCCESS', normalized: normalize(data[property], pData[property])}));
      }
    });
    return yields
}