import { omit } from "lodash";

export function autoReduce (action: any, state: any) {
    if (action.normalized) {
        Object.keys(action.normalized.entities).forEach((key) => {
			state = {
				...state,
				[key]: {
					...state[key],
					...action?.normalized?.entities[key]
				}
			}
            
        });
    }
    if (action.removeKeys) {
        for (let i = 0; i < action.removeKeys.length; i += 1) {
            state[action.removeKeys[i].entities] = omit(
                state[action.removeKeys[i].entities],
                action.removeKeys[i].ids
            );
			// state[action.removeKeys[i].entities] = {
			// 	...[action.removeKeys[i].entities]
			// }
			// console.log("TRIGGER THE ASSIGN")
			// Object.assign(state[action.removeKeys[i].entities], state[action.removeKeys[i].entities])
        }
    }
    if (action.overrides){
        action.overrides.forEach((x: any) => {
            state[x.key] = x.value;
        });
    }
    if (action.mergeKeys){
        action.mergeKeys.forEach((x: any) => {
            state[x.key] = {
                ...state[x.key],
                ...x.value
            };
        });
    }
	if (action.forceStateRefresh){
		console.log("forcing refresh?")
		return {
			...state,
			force_state_refresh: state.force_state_refresh + 1
		}
	}
    return state;
}
