
import React from 'react';

interface IAdminDashboard {

}

 
const AdminDashboard: React.FC<IAdminDashboard> = (props) => {
	return (<div>Dashboard Placeholder</div>)
};

export default AdminDashboard;
