/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import '../../compiled.css';

const HeaderComponent: React.FC = () => {
  return (
    <>
      <section id="CP_Header" className="py-4 container-fluid">
        <div className="row align-items-center">
          <div className="col-auto mx-auto me-lg-auto ms-lg-0">
            <a id="logo" className="text-center text-lg-start black">
              <img
                src="https://cp7.cpasitesolutions.com/~fileercc/images/New%20ERC%20Group%20LOGO.png"
                alt="ERC Specialists | Home | The ERC Group"
              />
            </a>
          </div>
          <div className="col-auto d-none d-lg-block text-center text-uppercase fs-5" id="CP_phone">
            <span className="d-block text-black font-normal">Call Us Today</span>
            <a href="tel:(214) 305-9104" className="text-decoration-none">
              (214) 305-9104
            </a>
          </div>
        </div>
      </section>
      <section className="landing__section--hero 2xl:pb-32 xl:pb-32 2xl:border-none xl:border-none border-solid border-t-2 border-b-0 border-x-0 border-y-primary block 2xl:hidden xl:hidden">
        <div className="grid grid-cols-2 gap-4 xl:px-64 2xl:px-64 lg:px-64 px-8">
          <div className="2xl:col-span-1 xl:col-span-1 lg:col-span-2 md:col-span-2 sm:col-span-2 col-span-2  2xl:block xl:block  2xl:border-hidden xl:border-hidden border-solid 2xl:border-t-0 xl:border-t-0 lg:border-t-0 md:border-t-0 border-t-0 border-b-0 border-t-primary border-x-second border-b-second mx-auto">
            <div className="col-span-auto hidden 2xl:block xl:block lg:block text-center uppercase" id="CP_phone">
              <span className="block">Call Us Today</span>
              <br />
              <a href="tel:(214) 305-9104" className="text-decoration-none">
                {' '}
                (214) 305-9104{' '}
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeaderComponent;
