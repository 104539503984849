import React from 'react';
import '../../compiled.css';

function HeroComponent(props:any) {
  console.log(props);
  return (
    <section id="CP_Hero" className="d-flex flex-column bg-image py-5">
      <div className="overlay"></div>
      <div id="CP_Tagline" className="container-fluid my-auto">
        <div className="row align-items-center">
          <p
            className="display-3 serif fst-italic white my-0 my-5 text-shadow aos-init aos-animate"
            data-aos="fade-in"
            data-aos-duration="1500"
            data-aos-delay="500"
            data-aos-offset="0"
          >
            {props.section}
          </p>
        </div>
      </div>
    </section>
  );
};

export default HeroComponent;
