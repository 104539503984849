import React, { useEffect, useState } from 'react';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../shared/MaterialTableShared';
import ToggleOn from '@mui/icons-material/ToggleOn';
import ToggleOff from '@mui/icons-material/ToggleOff';
import IsAdminUser from '@mui/icons-material/VerifiedUser';
import NotAdminUser from '@mui/icons-material/VerifiedUserOutlined';
import Visibility from '@mui/icons-material/Visibility';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import { COLORS } from '../../enums/layout-enums';
import { Button, Container, Divider, IconButton, List, ListItem, ListItemText, Stack, TextField, Tooltip, Typography } from '@mui/material';
import StandardModal from '../shared/StandardModal';
import { useDispatch, useSelector } from 'react-redux';
import { addRemoveRole, userActivation } from '../../store/actions/userActions';
import Loading from '../general/Loading';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import HttpClient, { objectToQuery } from '../../api/HttpClient';
import { formatInTimeZone } from 'date-fns-tz'
import { isValidDate } from '../../shared/utils/date-utils'
import { Delete, NoteAlt, TextSnippet } from '@mui/icons-material';
import { flexbox } from '@mui/system';
import { triggerAsyncId } from 'async_hooks';
import Edit from '@mui/icons-material/Edit';
import UserNotes from '../userNotes/userNotes';
import { IRootState } from '../../interfaces/store';
import { getQuestionSets, updateQuestionSet } from '../../store/actions/dataCollectionActions';
import { IQuestionSet } from '../../shared/interfaces/model/question-set.interface';
import UpsertQuestionSetModal from '../../pages/private/data-collection/UpsertQuestionSet';

const client = new HttpClient();
interface IQuestionSetTable {
	filterActive: boolean
}

const iconStyle = {
	color: COLORS.PURPLE_BRAND_PRIMARY, fontSize: 24, cursor: 'pointer'
}

const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

const QuestionSetTable: React.FC<IQuestionSetTable> = (props) => {

	const dispatch = useDispatch();
	const history = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [processing, setProcessing] = useState(true);
	const [upsertModalOpen, setUpsertModalOpen] = useState(false);
	const [selectedQuestionSet, setSelectedQuestionSet] = useState({} as IQuestionSet)
	const [filteredQuestionSets, setFilteredQuestionSets] = useState([] as IQuestionSet[]);
	const [triggerUserNotesModalOpen, setTriggerUserNotesModalOpen] = useState(false);
	const [activationModalOpen, setActivationModalOpen] = useState(false)
	const [userAdminStatusModalOpen, setUserAdminStatusModalOpen] = useState(false);
	const [tableKey, setTableKey] = useState(0);

	const questionSets = useSelector(
		(state: IRootState) => state.main.question_sets
	);


	useEffect(() => {
		dispatch(getQuestionSets({}, () => {
			setProcessing(false)
		}))
	}, [])

	// useEffect(() => {
	// 	console.log("filter changed")
	// 	setTableKey(tableKey + 1)
	// }, [props.filterActive])

	useEffect(() => {
		setTableKey(tableKey + 1)
		const questionSetArr = Object.values(questionSets);
		if (props.filterActive){
			setFilteredQuestionSets(questionSetArr.filter((x) => x.active === true))
		}
		else{
			setFilteredQuestionSets(questionSetArr)
		}
	}, [questionSets, props.filterActive])

	//   const [rowData, setRowData] = useState({} as any);
	const columns = [
		{
			title: 'Id', field: 'id', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Name', field: 'name', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'UUID', field: 'uuid', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Description', field: 'description', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Created', field: 'created_at', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Updated', field: 'updated_at', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: "Actions",
			render: (row: any) => {
				return (
					<div style={{ cursor: 'pointer' }}>
						<Tooltip title={"View Question Set"} placement="top">
							<Visibility
								style={iconStyle}
								onClick={() => {
									history('/QuestionSet/' + row.id)
								}}
							/>
						</Tooltip>
						<Tooltip title={"Edit Question Set"} placement="top">
							<Edit
								style={iconStyle}
								onClick={() => {
									setSelectedQuestionSet(row);
									setUpsertModalOpen(true)
								}}
							/>
						</Tooltip>
						{row.active ? <Tooltip title={"Deactivate"} placement="top">
							<ToggleOn
								style={iconStyle}
								onClick={() => {
									toggleActive(row.id, false)
								}}
							/>
						</Tooltip> :
							<Tooltip title={"Activate"} placement="top">
								<ToggleOff
									style={iconStyle}
									onClick={() => {
										toggleActive(row.id, true)
									}}
								/>
							</Tooltip>
						}
					</div>
				);
			}
		}
	];

	const toggleActive = (questionSetId: number, active: boolean) => {
		setProcessing(true)
		dispatch(updateQuestionSet({
			id: questionSetId,
			active
		}, (data: any) => {
			setProcessing(false)
			enqueueSnackbar(
				`Question set updated.`,
				{ variant: "success" }
			);
		})
		)
	}

	return (
		<div>
			<UpsertQuestionSetModal
				key={upsertModalOpen.toString()}
				mode={'edit'}
				setOpen={setUpsertModalOpen}
				open={upsertModalOpen}
				initialValues={selectedQuestionSet}
			/>
			<Loading loading={processing} />
			<div key={tableKey + "_" + props.filterActive}>
				<MaterialTable
					icons={tableIcons}
					title={'Question Sets'}
					columns={columns}
					data={filteredQuestionSets}
					options={{
						sorting: true,
						search: true,
						pageSize: 25,
						pageSizeOptions: [
							25, 50, 100, 200
						]
					}}
				/>
			</div>
		</div>

	);
};

export default QuestionSetTable;
