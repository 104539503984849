import React from "react";
import "../compiled.css";

const Home: React.FC = () => {
  return (
    <>
      <section id="CP_Content" className="px-52 CP_Content">
        <div
          className="container py-5 aos-init aos-animate"
          data-aos="fade-in"
          data-aos-duration="1500"
        >
          <div className="flex grid-cols-12 py-5 flex-wrap justify-center bg-color-3">
            <div className="col-span-10">
              <h1 className="text-center text-4xl">
                Employee Retention Tax Credit Specialists
              </h1>
              <p className="h3 text-center text-3xl mt-0">
                Millions Recovered for Our Clients!
              </p>
              <hr className="line bg-color-1 mb-4 mx-auto" />
              <p className="lead text-lg font-light	">
                See if You Qualify Today
              </p>
              <p className="lead text-center mt-4 mb-0"></p>
            </div>
          </div>
        </div>
      </section>
      <section className="CP_Content">
        <p className="h1 text-center text-4xl m-0 text-black">
          Take Advantage of this COVID-19 Relief Program
        </p>
        <p className="lead text-center text-lg font-normal text-black">
          You've worked hard. You deserve the best working for you.
        </p>
        <hr className="line bg-color-1 mb-4 mx-auto" />
        <div className="text-center h6 lh-1 text-shadow overflow-hidden my-0">
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:xl:grid-cols-3 justify-center gap-x-6	">
            <div
              className="col mb-4 aos-init aos-animate"
              data-aos="fade-in"
              data-aos-duration="1500"
            >
              <div className="view hm-zoom">
                <img
                  alt=""
                  className="img-fluid"
                  src="./assets/landing/tax-min.jpg"
                />
                <div className="mask overlay">
                  <a className="flex w-full h-full no-underline items-center justify-center flex-col">
                    <span className="h1 text-[2.5rem] text-white m-0">
                      Quick ERC Application
                    </span>
                    <br />
                    <span className="uppercase text-white">
                      Our 3 Step Process is Easy and Quick
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col mb-4 aos-init aos-animate"
              data-aos="fade-in"
              data-aos-duration="1500"
              data-aos-delay="200"
            >
              <div className="view hm-zoom">
                <img
                  alt=""
                  className="img-fluid"
                  src="./assets/landing/ind-min.jpg"
                />
                <div className="mask overlay">
                  <a className="flex w-full h-full text-white no-underline items-center justify-center flex-col">
                    <span className="h1 text-[2.5rem] text-white m-0">
                      Easy to Track
                    </span>
                    <br />
                    <span className="uppercase text-white">
                      We'll Update You Every Step of the Way
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col mb-4 aos-init aos-animate"
              data-aos="fade-in"
              data-aos-duration="1500"
              data-aos-delay="400"
            >
              <div className="view hm-zoom">
                <img
                  alt=""
                  className="img-fluid"
                  src="./assets/landing/bus-min.jpg"
                />
                <div className="mask overlay">
                  <a className="flex w-full h-full text-white no-underline items-center justify-center flex-col">
                    <span className="h1 text-[2.5rem] text-white m-0">
                      Your ERC Expert Team
                    </span>
                    <br />
                    <span className="uppercase text-white">
                      A literal "Dream Team" of Tax and
                      <br />
                      Accounting Experts Working for You
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="text-black">
        <div
          className="container-xxl py-5 pt-4 aos-init aos-animate"
          data-aos="fade-in"
          data-aos-duration="1500"
        >
          <div className="row py-5 justify-content-center sm:px-4 md:px-4 lg:px-4 xl:px-4 2xl:px-4 px-4 sm:pr-4 md:pr-4 lg:pr-4 rl:pr-4 2rl:pr-4 pr-4">
            <div className="col-lg-8">
              <p className="h1 text-center sm:mr-3 md:mr-3 lg:mr-3 rl:mr-3 2rl:mr-3 mr-3">
                A Suite of Tools and Experts at Your Disposal
              </p>
              <p className="lead text-center font-normal">
                Working for you to achieve the maximum possible retention
                credit.
              </p>
              <hr className="line bg-color-1 mb-4 mx-auto" />

              <p className="h2">Employee Retention Tax Credit Consulting</p>
              <p className="lead font-normal">
                We are here to answer questions, provide great advice, and get
                your business the maximum Employee Retention Tax Credit
                possible.
              </p>
              <br />
              <p className="h2">Do I Qualify?</p>
              <p className="lead font-normal">
                It's easy. Just answer a few simple questions to see if you
                qualify for a potential Employee Retention Credit (ERC).
              </p>
              <br />
              <p className="h2">Frequently Asked Questions (FAQs)</p>
              <p className="lead font-normal sm:mr-3 md:mr-3 lg:mr-3 rl:mr-3 2rl:mr-3 mr-3">
                Want to learn more? Simply click below for some commonly asked
                questions about the Employee Retention Tax Credit (ERC / ERTC)
                Program.
              </p>

              <p className="text-center mt-4 mb-0">
                <a className="btn btn-lg btn-default px-sm-5 py-3">
                  Learn More about ERC
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
