import { ACTIONS } from "../../enums/actions";

export const upload941 = (
	payload: any,
	onSuccess?: (data: any) => void,
	onFail?: (errors: any) => void,
	onProgress?: (percent: number) => void
) => ({
	payload,
	type: ACTIONS.UPLOAD_941,
	onSuccess,
	onFail,
	onProgress
});

export const return941 = (
	payload: any,
	onSuccess?: (data: any) => void,
	onFail?: (errors: any) => void,
	onProgress?: (percent: number) => void
) => ({
	payload,
	type: ACTIONS.RETURN_941,
	onSuccess,
	onFail,
	onProgress
});
