import { Delete, Edit } from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import Done from "@mui/icons-material/Done";
import { ListItem, IconButton, ListItemText, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { COLORS } from "../../enums/layout-enums";
import { IRootState } from "../../interfaces/store";

const iconStyle = {
    color: COLORS.PURPLE_BRAND_PRIMARY, fontSize: 24, cursor: 'pointer'
}
  

interface IUserNoteProp {
    note?: string;
    dateCreated?: any;
    updateNote: Function;
    deleteNote: Function;
    noEditMade: Function;
    editing?: any;
}

const UserNote :  React.FC<IUserNoteProp> = (props) => {
    const [editable, setNoteEditable] = useState(props.editing);
    const [updatedNote, setUpdatedNote] = useState(props.note)

    useEffect(() => {
        setUpdatedNote(props.note);
        setNoteEditable(props.editing);
    }, [props.editing])

    const currentTimezone = useSelector((state: IRootState) => state.main.timezone);

    return (            
    <ListItem secondaryAction={
    <>              
        
    {editable ? 
        <>            
            <IconButton
                style={iconStyle}
                edge="end"
                aria-label="Close"
                onClick={() => {
                    props.noEditMade();
                    setUpdatedNote(props.note);
                    setNoteEditable(false);
                }}
            >
                <Close color="disabled"/>
            </IconButton>
            <IconButton
                style={iconStyle}
                edge="end"
                aria-label="Edit"
                onClick={() => {
                    setUpdatedNote(updatedNote);
                    props.updateNote(updatedNote);
                    setNoteEditable(false);
                }}
            >
                <Done/>
            </IconButton>
        </>
        :      
        <>
            <IconButton
                style={iconStyle}
                edge="end"
                aria-label="Edit"
                onClick={() => {  
                  props.deleteNote()
                  setNoteEditable(false);
                }}
            >
                <Delete color={'error'}/>
            </IconButton>
            <IconButton
                style={iconStyle}
                edge="end"
                aria-label="Edit"
                onClick={() => {
                    setUpdatedNote(props.note);
                    setNoteEditable(true);
                }}
            >
                <Edit/>
            </IconButton>
        </>
    }
        

        </>
    }>
    {editable ? 
        <ListItemText 
            primary={
                <TextField  
                    style={{width: "85%"}} 
                    id='outlined-textarea' 
                    placeholder='Placeholder' 
                    multiline 
                    variant="standard" 
                    value={updatedNote}
                    onChange={(e) => {
                        setUpdatedNote(e.target.value);
                    }}
                />
            }  
            secondary={"Created | " + new Date(props.dateCreated)}
        />
    :
        <ListItemText primary={<p style={{width: "85%"}}>{props.note}</p>}  secondary={"Created | " + props.dateCreated}/>
    }
    </ListItem>
    )

}

export default UserNote;