/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import '../../compiled.css';

const ContactComponent: React.FC = () => {
  return (
    <section id="CP_Contact">
      <div className="container-xxl py-5 aos-init aos-animate" data-aos="fade-in" data-aos-duration="1500">
        <div className="row py-5 justify-content-center" id="CP_Icons">
          <div className="col-lg-8">
            <p className="h1 text-center text-black">
              Please call us today at{' '}
              <a href="tel:(214) 305-9104" className="text-decoration-none text-nowrap black">
                (214) 305-9104.
              </a>
            </p>
            <hr className="line bg-color-1 mb-3 mx-auto" />
            <div className="row row-cols-1 row-cols-sm-2 justify-content-center text-center">
              <div className="col my-3">
                <a data-bs-toggle="modal" href="#modalContact" className="text-decoration-none icon">
                  {/*  <FontAwesomeIcon icon={['fas', 'comments']} className="mb-2 fa fa-comments-o" /> */}
                  <p className="m-0 text-uppercase">Ask A Question</p>
                </a>
              </div>
              <div className="col my-3">
                <a href="" className="text-decoration-none icon">
                  {/*  <FontAwesomeIcon icon={['fas', 'comments']} className="mb-2 fa fa-copy" /> */}
                  <p className="m-0 text-uppercase">Send A File</p>
                </a>
              </div>
            </div>
            <p className="lead text-center font-normal text-black sm:px-8 md:px-8 lg:pr-0 xl:pr-0 2xl:pr-0 px-8">
              We're here to help. Fill out this form to see if you qualify for the ERC.
            </p>
            <p className="text-center mt-4 mb-0">
              <a className="btn btn-default px-sm-5 py-3">Get Started Today</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactComponent;
