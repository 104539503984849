import React, { useState } from 'react';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../shared/MaterialTableShared';
import ToggleOn from '@mui/icons-material/ToggleOn';
import ToggleOff from '@mui/icons-material/ToggleOff';
import IsAdminUser from '@mui/icons-material/VerifiedUser';
import NotAdminUser from '@mui/icons-material/VerifiedUserOutlined';
import Visibility from '@mui/icons-material/Visibility';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import { COLORS } from '../../enums/layout-enums';
import { Button, Container, Divider, IconButton, List, ListItem, ListItemText, Stack, TextField, Tooltip, Typography } from '@mui/material';
import StandardModal from '../shared/StandardModal';
import { useDispatch, useSelector } from 'react-redux';
import { addRemoveRole, userActivation } from '../../store/actions/userActions';
import Loading from '../general/Loading';
import { useSnackbar } from 'notistack';
import { useNavigate  } from 'react-router';
import HttpClient, { objectToQuery } from '../../api/HttpClient';
import { formatInTimeZone } from 'date-fns-tz'
import { isValidDate } from '../../shared/utils/date-utils'
import { Delete, NoteAlt, TextSnippet } from '@mui/icons-material';
import { flexbox } from '@mui/system';
import { triggerAsyncId } from 'async_hooks';
import Edit from '@mui/icons-material/Edit';
import UserNotes from '../userNotes/userNotes';
import { IRootState } from '../../interfaces/store';

const client = new HttpClient();
interface IUserTable {
	title: string,
	fixed_query_params: Object
}

const iconStyle = {
	color: COLORS.PURPLE_BRAND_PRIMARY, fontSize: 24, cursor: 'pointer'
}

const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

const UserTable: React.FC<IUserTable> = (props) => {

	const dispatch = useDispatch();
	const history = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [processing, setProcessing] = useState(false);
	const [selectedUserData, setSelectedUserData] = useState({} as any)
	const [inStoreModalOpen, setInStoreModalOpen] = useState(false);
	const [triggerUserNotesModalOpen, setTriggerUserNotesModalOpen] = useState(false);
	const [activationModalOpen, setActivationModalOpen] = useState(false)
	const [userAdminStatusModalOpen, setUserAdminStatusModalOpen] = useState(false);
	const [userTableKey, setUserTableKey] = useState(0);

	const currentTimezone = useSelector((state: IRootState) => state.main.timezone);

	//   const [rowData, setRowData] = useState({} as any);
	const { title } = props;
	const columns = [
		{
			title: 'Id', field: 'id', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'First Name', field: 'first_name', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Last Name', field: 'last_name', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Phone', field: 'phone', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Email', field: 'email', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: "User Actions",
			render: (row: any) => {
				return (
					<div style={{ cursor: 'pointer' }}>
						<Tooltip title={"View User"} placement="top">
							<Visibility
								style={iconStyle}
								onClick={() => {
									history('/Users/' + row.id)
								}}
							/>
						</Tooltip>
						<Tooltip title="User Notes" placement='top'>
							<TextSnippet
								style={iconStyle}
								onClick={() => {
									setSelectedUserData({
										...row
									})
									setTriggerUserNotesModalOpen(true);
								}}
							/>
						</Tooltip>
						{row.active ? <Tooltip title={"Deactivate"} placement="top">
							<ToggleOn
								style={iconStyle}
								onClick={() => {
									setSelectedUserData({
										...row
									})
									setActivationModalOpen(true);
								}}
							/>
						</Tooltip> :
							<Tooltip title={"Activate"} placement="top">
								<ToggleOff
									style={iconStyle}
									onClick={() => {
										setSelectedUserData({
											...row
										})
										setActivationModalOpen(true);
									}}
								/>
							</Tooltip>
						}
						{row.roles?.includes('Admin') ? <Tooltip title={"Demote From Admin"} placement="top">
							<IsAdminUser
								style={iconStyle}
								onClick={() => {
									setSelectedUserData({
										...row
									})
									setUserAdminStatusModalOpen(true);
								}}
							/>
						</Tooltip> :
							<Tooltip title={"Promote to Admin"} placement="top">
								<NotAdminUser
									style={iconStyle}
									onClick={() => {
										setSelectedUserData({
											...row
										})
										setUserAdminStatusModalOpen(true);
									}}
								/>
							</Tooltip>
						}
					</div>
				);
			},
		}
	];

	return (
		<div>
			<Loading loading={processing} />
			<StandardModal
				title={'Activation Modal'}
				open={activationModalOpen}
				onClose={() => setActivationModalOpen(false)}
				paragraphs={[`Would you like to ${selectedUserData.active ? 'deactivate' : 'activate'} ${selectedUserData.email}?`]}
				actions={[{
					title: 'Cancel',
					callback: () => {
						setActivationModalOpen(false)
					}
				}, {
					title: 'Yes',
					callback: () => {
						setProcessing(true)
						dispatch(userActivation({ user_id: selectedUserData.id, active: !selectedUserData.active }
							, () => {
								enqueueSnackbar(
									`User active status changed ${selectedUserData.email}.`,
									{ variant: "success" }
								)
								setActivationModalOpen(false)
									;
								setUserTableKey(userTableKey + 1);
								setProcessing(false)
							}, () => {
								enqueueSnackbar(
									`Error changing the active status of user.`,
									{ variant: "error" }
								);
								setProcessing(false)
								setActivationModalOpen(false)
							}))
					}
				}]}
				customActions={[]}
			/>
			<StandardModal
				title={'Alter User Admin Status'}
				open={userAdminStatusModalOpen}
				onClose={() => setUserAdminStatusModalOpen(false)}
				paragraphs={[`Would you like to ${selectedUserData.roles?.includes('Admin') ? 'demote' : 'promote'} ${selectedUserData.email} from Admin Status?`]}
				actions={[{
					title: 'Cancel',
					callback: () => {
						setUserAdminStatusModalOpen(false)
					}
				}, {
					title: 'Yes',
					callback: () => {
						setProcessing(true);
						dispatch(addRemoveRole({ user_id: selectedUserData.id, promote: !selectedUserData.roles?.includes('Admin'), role: 'Admin' }
							, () => {
								enqueueSnackbar(
									`User admin status changed ${selectedUserData.email}.`,
									{ variant: "success" }
								)
								setUserAdminStatusModalOpen(false)
									;
								setUserTableKey(userTableKey + 1);
								setProcessing(false);
							}, () => {
								enqueueSnackbar(
									`Error changing the admin status of user.`,
									{ variant: "error" }
								);
								setProcessing(false);
								setUserAdminStatusModalOpen(false)
							}))
					}
				}]}
				customActions={[]}
			/>
			<StandardModal
				title={"User Notes"}
				open={triggerUserNotesModalOpen}
				onClose={() => setTriggerUserNotesModalOpen(false)}

			>
				{/* For now, hardcode location id as 1 */}
				<UserNotes
					user_id={selectedUserData.id}
					location_id={1}
				/>
			</StandardModal>
			<div key={userTableKey}>
			<MaterialTable
				icons={tableIcons}
				title={title}
				columns={columns}
				data={(query) => new Promise((resolve, reject) => {
					let url = 'api/user/query?';
					url += `take=${query.pageSize}&`;
					url += `skip=${query.page * query.pageSize}&`;
					if (query.orderBy?.field) {
						url += `orderBy=${String(query.orderBy.field)}&`
					}
					if (query.orderDirection) {
						url += `orderDirection=${query.orderDirection}&`
					}
					url += `search=${query.search}`;
					if (Object.keys(props.fixed_query_params).length) {
						url += "&"
						url += objectToQuery(props.fixed_query_params, false)
					}
					(async () => {
						try {
							const result = await client.get(url);
							console.log("RESULT.DATA>", result.data)
							if (result.data) {
								resolve({
									data: result.data.data,
									page: query.page,
									totalCount: result.data.total,
								});
							} else {
								reject(new Error('Un-Authorized'));
							}
						} catch (error) {
							reject(error);
						}
					})();
				})}
				options={{
					sorting: true,
					search: true,
					pageSize: 25,
					pageSizeOptions: [
						25, 50, 100, 200
					]
				}}
			/>
			</div>
		</div>

	);
};

export default UserTable;
