import React, { useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress, TextField } from "@mui/material";
import { COLORS } from "../../enums/layout-enums";
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionsByUUID } from "../../store/actions/dataCollectionActions";
import { useSnackbar } from "notistack";
import { upload941 } from "../../store/actions/formRecognizerActions";
import Loading from "../general/Loading";

interface IFormUpload {

}

const FormUpload: React.FC<IFormUpload> = (props) => {

    const dispatch = useDispatch();
    const [form941Collection, setForm941Collection] = useState<FileList | null>(null);
    const [form941CollectionKey, setForm941CollectionKey] = useState(1);
    const [processing, setProcessing] = useState(false);
    const [dynamicLink, setDynamicLink] = useState("");
    const { enqueueSnackbar } = useSnackbar();

    const onUpload = (e: React.SyntheticEvent) => {
        e.preventDefault();
        const formData = new FormData();
        if (form941Collection && form941Collection[0]) {
            formData.append('file', form941Collection[0]);
            setProcessing(true);
            dispatch(upload941(formData, (data: any) => {
                enqueueSnackbar(
                    "941 has been uploaded",
                    { variant: "success" }
                );
                setForm941Collection(null)
                setProcessing(false);
                console.log("setting dynamic link?", data)
                setDynamicLink(process.env.REACT_APP_API_HOST + '/api/form-recognizer/return_941?fileName=' + data.fileName)
            }, (errors: any) => {
                errors.forEach((error: any) => {
                    enqueueSnackbar(
                        'Row ' + error.row + ': ' + error.error,
                        { variant: "error" }
                    );
                })
                setForm941Collection(null)
                setProcessing(false);
            }))
        }
    };

    const onForm941Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm941Collection(e.target.files);
    };



    return (
        <div key={dynamicLink}>
            <Loading loading={processing} />
            <form onSubmit={onUpload}>
                <div>
                    <TextField
                        type="file"
                        key={form941CollectionKey}
                        name="uploadForm941Input"
                        onChange={onForm941Change}
                        margin="normal"
                    />
                    <br />
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={!form941Collection}
                    >Upload 941 PDF
                    </Button>
                </div>
            </form>
            {dynamicLink !== "" ? <a target={'_blank'} href={dynamicLink}>Download</a> :  null}
        </div>
    );
};

export default FormUpload;
