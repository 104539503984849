/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import '../../compiled.css';

const SlideComponent: React.FC = () => {
  return (
    <section id="CP_Slider" className="parallax bg-image d-flex align-items-center white text-shadow">
      <div className="overlay"></div>
      <div
        className="container-fluid text-center py-5 aos-init aos-animate"
        data-aos="fade-in"
        data-aos-duration="1500"
      >
        <p className="h5 text-uppercase">Testimonials</p>
        <hr className="line bg-white mx-auto my-4" />
        <div id="CP_Testimonials" className="carousel slide" data-bs-ride="carousel" data-bs-interval="8000">
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#CP_Testimonials"
              data-bs-slide-to="0"
              className="active"
              aria-label="Slide 1"
              aria-current="true"
            ></button>
            <button
              type="button"
              data-bs-target="#CP_Testimonials"
              data-bs-slide-to="1"
              aria-label="Slide 2"
              className=""
            ></button>
            <button
              type="button"
              data-bs-target="#CP_Testimonials"
              data-bs-slide-to="2"
              aria-label="Slide 3"
              className=""
            ></button>
            <button
              type="button"
              data-bs-target="#CP_Testimonials"
              data-bs-slide-to="3"
              aria-label="Slide 4"
              className=""
            ></button>
          </div>
          <div className="carousel-inner align-items-center d-flex pb-4">
            <div className="carousel-item align-self-center active" style={{ minHeight: '76px' }}>
              <p className="fs-1 serif fst-italic">"These guys got me back in business."</p>
            </div>
            <div className="carousel-item align-self-center" style={{ minHeight: '76px' }}>
              <p className="fs-1 serif fst-italic">
                "With The ERC Group, each client receives close personal and professional attention."
              </p>
            </div>
            <div className="carousel-item align-self-center" style={{ minHeight: '76px' }}>
              <p className="fs-1 serif fst-italic">"The ERC Group is professional and responsive."</p>
            </div>
            <div className="carousel-item align-self-center" style={{ minHeight: '76px' }}>
              <p className="fs-1 serif fst-italic">"The ERC Group is known for quality service."</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SlideComponent;
