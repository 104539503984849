import React from "react";
import "../compiled.css";

const AboutPage: React.FC = () => {
  return (
    <section id="CP_Content">
      <div className="container-fluid py-4">
        <div id="CP_Breadcrumbs" className="text-center text-lg-start m-0">
          <a className="text-nowrap">
            <span className="fa fa-home"></span> Home
          </a>
          <span className="text-nowrap">About</span>
        </div>
        <div className="row sm:pr-12 md:pr-12 lg:pr-0 xl:pr-0 2xl:pr-0 pr-12">
          <div className="py-5 col-xxl-7 col-xl-7" id="content">
            <div id="Header" className="text-left">
              <table width="100%">
                {/* border="0" cellspacing="0" cellpadding="0"*/}
                <tbody>
                  <tr>
                    <td width="85">
                      {/*  rowspan="2" */}

                      <div className="shadows">
                        <div className="globalItem">
                          <img
                            src="/images/galleries/header/css/shadows.png"
                            width="100"
                            height="96"
                            alt="About"
                          />
                          {/* border="0" align="left" */}
                          <div className="inner-shadows">
                            <img
                              src="/images/galleries/header/square/FirmProfile/profile.png"
                              width="78"
                              height="78"
                            />
                            {/* align="left" border="0" */}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td valign="bottom">
                      <h1>About</h1>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" style={{ paddingTop: "2px" }}>
                      <table width="100%">
                        {/*  border="0" cellpadding="0" cellspacing="0" height="2" */}
                        <tbody>
                          <tr>
                            <td className="cellcolor" width="100%">
                              <img
                                src="/images/galleries/header/hrshade.png"
                                height="2"
                                width="100%"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
            </div>

            <div id="Text" className="text-left">
              <h1>About Us - Your Team of ERC Experts</h1>
              <p></p>
              <h2>Our Story</h2>
              <p>
                We are an experienced team of Tax, Accounting, and Legal experts
                who have come together to fight for you and get you the Employee
                Retention Tax Credit you deserve for your business.&nbsp;
              </p>
              <p>
                With decades of experience, our leadership owns or manages
                leading tax and accounting firms in their respective markets.
                The ERC Group evolved from a like minded group of industry
                leaders to a cohesive team of experts who have come together to
                fight for you.
              </p>
              <h2>Our Values</h2>
              <p>
                At the ERC Group, we are about extraordinary advice and doing
                what is best for our clients. As a business owner, you've taken
                risks, made commitments, and hung in there during the best and
                worst of times. We are committed to telling our clients what
                they need to hear and making sure they are positioned for the
                best possible outcome.
              </p>
            </div>

            <div id="ListSub" className="text-left">
              <table width="100%">
                {/* border="0" cellpadding="0" cellspacing="0" */}
                <tbody>
                  <tr>
                    <td
                      style={{ paddingBottom: "20px" }}
                      valign="top"
                      width="49%"
                    >
                      <div className="thumbborder thumbpad">
                        <a>
                          <img
                            src="/images/galleries/header/square/FirmProfile/profile.png"
                            alt="Our Team"
                          />
                        </a>
                      </div>
                      <div className="caption">
                        <strong>
                          <a target="_self">Our Team</a>
                        </strong>
                        <br />
                      </div>
                    </td>
                    <td width="2%">&nbsp;</td>
                    <td
                      style={{ paddingBottom: "20px" }}
                      valign="top"
                      width="49%"
                    >
                      <div className="thumbborder thumbpad">
                        <a>
                          <img
                            src="/images/galleries/header/square/People/service_dv.png"
                            alt="Industries We Serve"
                          />
                        </a>
                      </div>
                      <div className="caption">
                        <strong>
                          <a target="_self">Industries We Serve</a>
                        </strong>
                        <br />
                        Take a look at our About page. The ERC Group specializes
                        in Accounting in Dallas, TX.
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="col-xxl-3 col-xl-4 offset-xl-1 d-none d-xl-block"
            id="CP_Sidebar"
          >
            <section id="CP_Section" className="pt-5">
              <h3>In This Section</h3>
              <ul
                role="group"
                aria-hidden="true"
                aria-labelledby="sm-16668596823848872-1"
                aria-expanded="false"
                className="h5"
              >
                <li>
                  <a target="_top">Our Team</a>{" "}
                </li>
                <li>
                  <a target="_top">Industries We Serve</a>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutPage;
