import React, { useEffect, useState } from 'react';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../shared/MaterialTableShared';
import ToggleOn from '@mui/icons-material/ToggleOn';
import ToggleOff from '@mui/icons-material/ToggleOff';
import IsAdminUser from '@mui/icons-material/VerifiedUser';
import NotAdminUser from '@mui/icons-material/VerifiedUserOutlined';
import Visibility from '@mui/icons-material/Visibility';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import { COLORS } from '../../enums/layout-enums';
import { Button, Container, Divider, IconButton, List, ListItem, ListItemText, Stack, TextField, Tooltip, Typography } from '@mui/material';
import StandardModal from '../shared/StandardModal';
import { useDispatch, useSelector } from 'react-redux';
import { addRemoveRole, userActivation } from '../../store/actions/userActions';
import Loading from '../general/Loading';
import { useSnackbar } from 'notistack';
import { useNavigate  } from 'react-router';
import HttpClient, { objectToQuery } from '../../api/HttpClient';
import { formatInTimeZone } from 'date-fns-tz'
import { isValidDate } from '../../shared/utils/date-utils'
import { Delete, NoteAlt, TextSnippet } from '@mui/icons-material';
import { flexbox } from '@mui/system';
import { triggerAsyncId } from 'async_hooks';
import Edit from '@mui/icons-material/Edit';
import UserNotes from '../userNotes/userNotes';
import { IRootState } from '../../interfaces/store';
import { getQuestions, getQuestionSets, updateQuestion } from '../../store/actions/dataCollectionActions';
import { IQuestionSet } from '../../shared/interfaces/model/question-set.interface';
import UpsertQuestionSetModal from '../../pages/private/data-collection/UpsertQuestionSet';
import { IQuestion } from '../../shared/interfaces/model/question.interface';
import UpsertQuestionModal from '../../pages/private/data-collection/UpsertQuestion';
import { DATA_RESPONSE_TYPE } from '../../shared/constants/data-collection.constants';

const client = new HttpClient();
interface IQuestionTable {
	questionSetId: number
	filterActive: boolean
}

const iconStyle = {
	color: COLORS.PURPLE_BRAND_PRIMARY, fontSize: 24, cursor: 'pointer'
}

const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

const QuestionTable: React.FC<IQuestionTable> = (props) => {

	const dispatch = useDispatch();
	const history = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [processing, setProcessing] = useState(true);
	const [upsertModalOpen, setUpsertModalOpen] = useState(false);
	const [filteredQuestions, setFilteredQuestions] = useState([] as IQuestion[]);
	const [selectedQuestion, setSelectedQuestion] = useState({} as any)
	const [inStoreModalOpen, setInStoreModalOpen] = useState(false);
	const [triggerUserNotesModalOpen, setTriggerUserNotesModalOpen] = useState(false);
	const [activationModalOpen, setActivationModalOpen] = useState(false)
	const [userAdminStatusModalOpen, setUserAdminStatusModalOpen] = useState(false);
	const [questionSet, setQuestionSet] = useState<null | IQuestionSet>(null);
	const [tableKey, setTableKey] = useState(0);

	const questions = useSelector(
		(state: IRootState) => state.main.questions
	);

	const question_sets = useSelector(
		(state: IRootState) => state.main.question_sets
	);


	useEffect(() => {
		dispatch(getQuestions({question_set_id: props.questionSetId}, () => {
			setProcessing(false)
		}))
	}, [])

	useEffect(() => {
		const questionSetArr = Object.values(question_sets)
		const matchedQuestionSet = questionSetArr.find((x) => x.id === props.questionSetId);
		if (matchedQuestionSet){
			setQuestionSet(matchedQuestionSet);
		}
		else{
			dispatch(getQuestionSets({}, () => {
	
			}))
		}
	}, [question_sets])

	useEffect(() => {
		const questionsArr = Object.values(questions);
		let filteredArr = questionsArr.filter((x) => x.question_set_id === props.questionSetId)
		if (props.filterActive){
			filteredArr = filteredArr.filter((x) => x.active === true);
		}
		setFilteredQuestions(filteredArr)
		setTableKey(tableKey + 1)
	}, [questions, props.filterActive])

	const ellipsisDiv = (content: any) => {
		return (
			<div style={{
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis'
			}}>
				{content}
			</div>
		)

	}

	//   const [rowData, setRowData] = useState({} as any);
	const columns = [
		{
			title: 'Id', field: 'id', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Name', field: 'name', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Question Detail', field: 'question_detail', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Value Type', field: 'value_type', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Data', field: 'data', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}, render: (row: any) => {
				return (
					ellipsisDiv(JSON.stringify(row.data))
				);
			}
		},
		{
			title: "Actions",
			render: (row: any) => {
				return (
					<div style={{ cursor: 'pointer' }}>
						{/* <Tooltip title={"View Question"} placement="top">
							<Visibility
								style={iconStyle}
								onClick={() => {
									history('/QuestionSet/'+row.id)
								}}
							/>
						</Tooltip> */}
						<Tooltip title={"Edit Question"} placement="top">
							<Edit
								style={iconStyle}
								onClick={() => {
									let upsertQuestion = {...row}
									switch(upsertQuestion.value_type){
										case DATA_RESPONSE_TYPE.NUMBER:
											upsertQuestion.min = upsertQuestion.data.min
											upsertQuestion.max = upsertQuestion.data.max
											break;
										case DATA_RESPONSE_TYPE.NUMBER_WITH_TEXT_ALIASES:
											upsertQuestion.min = upsertQuestion.data.min
											upsertQuestion.max = upsertQuestion.data.max
											if (upsertQuestion.data.aliases){
												for (let i=0; i<upsertQuestion.data.aliases.length; i++){
													const cur = upsertQuestion.data.aliases[i]
													upsertQuestion[cur.number + "_alias"] = cur.val
												}
											}
											break;
									}
									delete upsertQuestion.data;
									setSelectedQuestion(upsertQuestion);
									setUpsertModalOpen(true)
								}}
							/>
						</Tooltip>
						{row.active ? <Tooltip title={"Deactivate"} placement="top">
							<ToggleOn
								style={iconStyle}
								onClick={() => {
									toggleActive(row.id, false)
								}}
							/>
						</Tooltip> :
							<Tooltip title={"Activate"} placement="top">
								<ToggleOff
									style={iconStyle}
									onClick={() => {
										toggleActive(row.id, true)
									}}
								/>
							</Tooltip>
						}
					</div>
				);
			}
		}
	];

	const toggleActive = (questionId: number, active: boolean) => {
		setProcessing(true)
		dispatch(updateQuestion({
			id: questionId,
			active
		}, (data: any) => {
			setProcessing(false)
			enqueueSnackbar(
				`Question updated.`,
				{ variant: "success" }
			);
		})
		)
	}

	return (
		<div>
			<UpsertQuestionModal
				key={upsertModalOpen.toString()}
				mode={'edit'}
				setOpen={setUpsertModalOpen}
				open={upsertModalOpen}
				initialValues={selectedQuestion} 
				questionSetId={props.questionSetId}
			/>
			<Loading loading={processing} />
			<div key={tableKey}>
			<MaterialTable
			icons={tableIcons}
				title={(questionSet?.name || '') + ' Questions'}
				columns={columns}
				data={Object.values(filteredQuestions)}
				options={{
					sorting: true,
					search: true,
					pageSize: 25,
					pageSizeOptions: [
						25, 50, 100, 200
					]
				}}
			/>
			</div>
		</div>

	);
};

export default QuestionTable;
