import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { COLORS, MARGIN_MEDIUM, MARGIN_SMALL } from "../../enums/layout-enums";
import { useDispatch } from 'react-redux';
import { getQuestionsByUUID } from "../../store/actions/dataCollectionActions";
import { useFormik } from "formik";
import { isEqual } from "lodash";
import {WageSheetTables} from "./WageSheetTables";
import PPPQuestions from "./PPPQuestions";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WageSheetUploader from "../shared/WageSheetUploader";
import { getWageWorksheetData } from "../../utils/erc-calculation-helpers";
interface ITaxCalculationContainer {
  initialValues: any
}

const TaxCalculationContainer: React.FC<ITaxCalculationContainer> = (props) => {

  const [qualified2020, setQualified2020] = useState(false);
  const [qualified2021, setQualified2021] = useState(false);
  const [pppData, setPPPData] = useState({} as any)

  const [ercCalculation2020, setERCCalculations2020] = useState({} as any);
  const [ercCalculation2021, setERCCalculations2021] = useState({} as any);

  const validate = (values: any) => {


    const qualified = {
      '2019_q1': false, //a
      '2019_q2': false, //b
      '2019_q3': false, //c
      '2019_q4': false, //d
      '2020_q1': false, //e
      '2020_q2': false, //f
      '2020_q3': false, //g
      '2020_q4': false, //h
      '2021_q1': false, //i
      '2021_q2': false, //j
      '2021_q3': false, //k
    }

    if (values['2020_q2'] < .5 * values['2019_q2']) {
      //console.log("F < 50% of B, F G and H are qualified")
      qualified['2020_q2'] = true; //f
      qualified['2020_q3'] = true; //g
    }
    if (values['2020_q3'] < .5 * values['2019_q3']) {
      qualified['2020_q3'] = true;
      qualified['2020_q4'] = true;
    }
    if (values['2020_q4'] < .5 * values['2019_q4']) {
      //console.log("H < 50% of D, I is qualified")
      qualified['2020_q4'] = true;
      qualified['2021_q1'] = true;
    }
    if (values['2020_q4'] < .8 * values['2019_q4']) {
      qualified['2021_q1'] = true
    }

    if (values['2021_q1'] < .8 * values['2019_q1']) {
      qualified['2021_q1'] = true;
      qualified['2021_q2'] = true;
    }
    if (values['2021_q2'] < .8 * values['2019_q2']) {
      qualified['2021_q2'] = true;
      qualified['2021_q3'] = true;
    }
    if (values['2021_q3'] < .8 * values['2019_q3']) {
      qualified['2021_q3'] = true;
    }
    const errors = {} as any;
    setQualifyingQuarters(qualified)

    if (qualified['2020_q1'] == true || qualified['2020_q2'] == true || qualified['2020_q3'] == true || qualified['2020_q4'] == true) {
      setQualified2020(true)
    }
    else {
      setQualified2020(false)
    }

    if (qualified['2021_q1'] == true || qualified['2021_q2'] == true || qualified['2021_q3'] == true) {
      setQualified2021(true)
    }
    else {
      setQualified2021(false)
    }

    return errors;
  };



  const dispatch = useDispatch();

  const [qualifyingQuarters, setQualifyingQuarters] = useState({} as any)
  const [ercCalcs, setErcCalcs] = useState({} as any)


  const formik = useFormik({
    initialValues: props.initialValues,
    validate,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: values => {
      console.log("submitted")
    },
  });
  useEffect(() => {
    dispatch(getQuestionsByUUID({ uuid: 'main' }))
  }, [])

  const rows = [
    { year: 2019, quarters: [1, 2, 3, 4] },
    { year: 2020, quarters: [1, 2, 3, 4] },
    { year: 2021, quarters: [1, 2, 3] },
  ]

  const quarterStrings = ['q1', 'q2', 'q3', 'q4']
  const MIN_CELL_HEIGHT = 40

  const renderFicaRows = (year: number) => {
    return quarterStrings.map((qString) => {

      const line_5a_col_2 = formik.values?.[year + '_' + qString + '_' + 'line_5a_col_2'] || 0
      //const line_5a_i_col_2 = formik.values?.[year + '_' + qString + '_' + 'line_5a_i_col_2'] || 0
      //const line_5a_ii_col_2 = formik.values?.[year + '_' + qString + '_' + 'line_5a_ii_col_2'] || 0
      const line_5b_col_2 = formik.values?.[year + '_' + qString + '_' + 'line_5b_col_2'] || 0
      const line_5c_col_2 = formik.values?.[year + '_' + qString + '_' + 'line_5c_col_2'] || 0
      const line_5d_col_2 = formik.values?.[year + '_' + qString + '_' + 'line_5d_col_2'] || 0

      const ficaValue = line_5a_col_2 + line_5b_col_2 // line_5a_i_col_2 + line_5a_ii_col_2

      let fica_50_percent = .5 * ficaValue

      if (qString === 'q3' && year === 2021) {
        fica_50_percent = (line_5c_col_2 + line_5d_col_2) * .5
      }
      const qualifying_50_percent_minus_x = (formik.values[(year - 1) + '_' + qString] * .5) - fica_50_percent

      const newValues = {
        fica_50_percent,
        qualifying_50_percent_minus_x,
        erc_calc: fica_50_percent + qualifying_50_percent_minus_x
      }

      if (!isEqual(ercCalcs?.[year + '_' + qString], newValues)) {
        setErcCalcs({
          ...ercCalcs,
          [year + '_' + qString]: {
            fica_50_percent,
            qualifying_50_percent_minus_x,
            erc_calc: fica_50_percent + qualifying_50_percent_minus_x
          },
          modified: new Date().getTime()
        })
      }

      return (<TableCell component="th" scope="row"><TextField
        label={qString.toUpperCase()}
        type={'number'}
        disabled={true} //!qualifyingQuarters[year + '_' + qString]
        size="small"
        name={'FICA_' + year + '_' + qString}
        onChange={(e) => {
          formik.handleChange(e)
        }}
        style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
        value={ficaValue} //qualifyingQuarters[year + '_' + qString] ? formik.values['FICA_' + year + '_' + qString] : 0
        variant="outlined"
        InputLabelProps={{
          shrink: true
        }}
      /></TableCell>)
    })
  }

  const verticalFlex = (dContent: any) => {
    return (
      <div style={{ height: MIN_CELL_HEIGHT, display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: 1 }}>
          {dContent}
        </div>
      </div>
    )
  }

  const renderFicaRows50Percent = (year: number) => {
    return quarterStrings.map((qString) => {
      return (<TableCell component="th" scope="row">
        {verticalFlex(
          <b>{ercCalcs?.[year + '_' + qString]?.fica_50_percent}</b>
        )}

      </TableCell>)
    })
  }

  const render50PercentQualifyingMinusX = (year: number) => {
    return quarterStrings.map((fRow) => {
      return (<TableCell component="th" scope="row">
        <div style={{ minHeight: MIN_CELL_HEIGHT, display: 'flex', alignContent: 'center' }}>
          <b>{ercCalcs?.[year + '_' + fRow]?.qualifying_50_percent_minus_x}</b>
        </div>
      </TableCell>)
    })
  }

  const renderERCCredit = (year: number) => {

    return quarterStrings.map((fRow) => {
      console.log("final", formik.values['QUALIFYING_50_PERCENT_MINUS_X' + year + '_' + fRow])
      console.log("final 2", formik.values['FICA_50_PERCENT' + year + '_' + fRow])
      return (<TableCell component="th" scope="row">
        <div style={{ minHeight: MIN_CELL_HEIGHT, display: 'flex', alignContent: 'center' }}>
          <b>{ercCalcs?.[year + '_' + fRow]?.erc_calc}</b>
        </div>
      </TableCell>)
    })
  }

  const renderHeadersTaxIntervals = (column1Title: string) => {
    return (
      <TableHead>
        <TableRow>
          <TableCell align="left">{column1Title}</TableCell>
          <TableCell align="left">Q1</TableCell>
          <TableCell align="left">Q2</TableCell>
          <TableCell align="left">Q3</TableCell>
          <TableCell align="left">Q4</TableCell>
        </TableRow>
      </TableHead>
    )
  }

  const linesFrom941 = [
    { title: 'Line 2', name: 'line_2' },
    { title: 'Line 3', name: 'line_3' },
    { title: 'Line 5a Col 2', name: 'line_5a_col_2' },
    { title: 'Line 5a(i) Col 2 – If applicable', name: 'line_5a_i_col_2' },
    { title: 'Line 5 (ii) col 2 – If applicable', name: 'line_5a_ii_col_2' },
    { title: 'Line 5b Col 2 – If applicable', name: 'line_5b_col_2' },
    { title: 'Line 5c Col 2', name: 'line_5c_col_2' },
    { title: 'Line 5d Col 2 – If applicable', name: 'line_5d_col_2' },
  ]
  const render941InputRows = (year: number) => {
    return linesFrom941.map((lineFrom941) => {
      return (
        <TableRow key={year + "_" + lineFrom941.name}>
          <TableCell style={{ maxWidth: 100 }} component="th" scope="row">
            {lineFrom941.title}
          </TableCell>
          {quarterStrings.map((qString) => {
            return (
              <TableCell component="th" scope="row">
                <TextField
                  sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                  disabled={!qualifyingQuarters[year + '_' + qString]}
                  // fullWidth={true}
                  label={''}
                  type={'number'}

                  size="small"
                  name={year + "_" + qString + "_" + lineFrom941.name}
                  onChange={formik.handleChange}
                  value={formik.values[year + "_" + qString + "_" + lineFrom941.name]}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </TableCell>
            )
          })}
        </TableRow>
      )
    })
  }


  const renderYearERCCalcs = (year: number) => {
    return (<TableContainer component={Paper}>
      <div style={{ marginLeft: MARGIN_MEDIUM }}>- Copy the following values from the indicated lines on your 941 quarterly tax returns</div>

      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        {renderHeadersTaxIntervals('941 Line')}
        <TableBody>
          <>
            {render941InputRows(year)}
            <TableRow key={'941Data_' + year}>
            </TableRow>
            <TableRow
              key={'FICA_' + year}
            >
              <TableCell component="th" scope="row">
                FICA
              </TableCell>
              {renderFicaRows(year)}

            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {verticalFlex(<b>
                  50% FICA-Non-Ref. - 941X -Part 3: Line 18
                </b>)}
              </TableCell>
              {renderFicaRows50Percent(year)}
            </TableRow>

            {/* <TableRow>
              <TableCell component="th" scope="row">
                {verticalFlex(<b>
                  50% Qualifying Wages - 941X - Part 3 - Line 26
                </b>)}
              </TableCell>
              {render50PercentQualifyingMinusX(year)}
            </TableRow> */}

            {/* <TableRow>
              <TableCell component="th" scope="row">
                {verticalFlex(<b>
                  Total ERC Credit
                </b>)}
              </TableCell>
              {renderERCCredit(year)}
            </TableRow> */}
          </>
        </TableBody>
      </Table>
    </TableContainer>)
  }

  const handleUploadWageWorksheetSuccess = (data: any) => {
    setERCCalculations2020(getWageWorksheetData(data, 'calcs2020'))
    setERCCalculations2021(getWageWorksheetData(data, 'calcs2021'))
  }



  return (
    <>

      <div>
        <Typography variant="h6" gutterBottom component="div">Determine Qualifying Wages 2020-2021</Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            {renderHeadersTaxIntervals('Year')}
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.year}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.year}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.quarters.includes(1) && <TextField
                      label="Q1"
                      type={'number'}

                      size="small"
                      name={row.year + '_q1'}
                      onChange={formik.handleChange}
                      onBlur={() => formik.validateForm()}
                      style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                      value={formik.values[row.year + '_q1']}
                      variant="outlined"
                    />}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.quarters.includes(2) && <TextField
                      label="Q2"
                      type={'number'}

                      size="small"
                      name={row.year + '_q2'}
                      onChange={formik.handleChange}
                      onBlur={() => formik.validateForm()}
                      style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                      value={formik.values[row.year + '_q2']}
                      variant="outlined"
                    />}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.quarters.includes(3) && <TextField
                      label="Q3"
                      type={'number'}

                      size="small"
                      name={row.year + '_q3'}
                      onChange={formik.handleChange}
                      onBlur={() => formik.validateForm()}
                      style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                      value={formik.values[row.year + '_q3']}
                      variant="outlined"
                    />}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.quarters.includes(4) && <TextField
                      label="Q4"
                      type={'number'}

                      size="small"
                      name={row.year + '_q4'}
                      onChange={formik.handleChange}
                      onBlur={() => formik.validateForm()}
                      style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                      value={formik.values[row.year + '_q4']}
                      variant="outlined"
                    />}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>Qualifying Quarters</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {<pre>{JSON.stringify(qualifyingQuarters, null, 4)}</pre>}

          </AccordionDetails>
        </Accordion>
      </div>



      <div>
        <PPPQuestions
          qualified2020={qualified2020}
          qualified2021={qualified2021}
          onPPPDataChange={(data: any) => {
            setPPPData(data)
          }
          }
        />
      </div>

      <div>
        <Typography variant="h6" gutterBottom component="div">Upload wage sheet to calculate ERC</Typography>
        <WageSheetUploader pppData={pppData} showToast={false} onSuccess={handleUploadWageWorksheetSuccess} />
      </div>


      {ercCalculation2020?.wageCalcs && <WageSheetTables
        key={1 + ercCalcs?.modified}
        pppData={pppData}
        initialValues={undefined}
        calcs={ercCalculation2020}
        ercCalcs={ercCalcs}
        year={2020}
      />}

      {renderYearERCCalcs(2020)}

        <br/><br/>
      {ercCalculation2021?.wageCalcs && <WageSheetTables
         key={2 + ercCalcs?.modified}
        pppData={pppData}
        initialValues={undefined}
        calcs={ercCalculation2021}
        ercCalcs={ercCalcs}
        year={2021}
      />}

      <br /><br />


      {renderYearERCCalcs(2021)}

    </>
  );
};

export default TaxCalculationContainer;
