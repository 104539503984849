import { takeEvery, takeLatest } from "redux-saga/effects"
import { ACTIONS } from "../../enums/actions"
import authSaga from "./authSaga"
import clientOptionSaga from './clientOptionsSaga'
import userSaga from "./userSaga"
import timezoneSaga from "./timezoneSaga"
import resourceSaga from "./resourceSaga"
import awsSaga from './awsSaga'
import genericSaga, { genericGet, genericPost, genericUploadPost } from './genericSaga'
import formRecognizerSaga from "./formRecognizerSaga"

export default function* rootSaga() {

  //Authentication
  yield takeLatest(ACTIONS.LOGIN, authSaga.login)
  yield takeLatest(ACTIONS.LOGOUT, authSaga.logout)
  yield takeLatest(ACTIONS.REQUEST_PASSWORD_RESET, authSaga.requestResetPassword)
  yield takeLatest(ACTIONS.PASSWORD_RESET, authSaga.resetPassword)

  //Client Options
  yield takeEvery(ACTIONS.SET_CLIENT_OPTIONS, clientOptionSaga.setclient_options)

  //Users
  yield takeLatest(ACTIONS.GET_USER, userSaga.getUser)
  yield takeLatest(ACTIONS.GET_USERS, userSaga.getUsers)
  yield takeLatest(ACTIONS.UPDATE_USER, userSaga.updateUser)
  yield takeLatest(ACTIONS.USER_ACTIVATION, userSaga.userActivation)
  yield takeLatest(ACTIONS.DOWNLOAD_USERS_REPORT, userSaga.downloadUsersReport)
  yield takeLatest(ACTIONS.GET_USER_ROLES, authSaga.getUserRolesByUser)
  yield takeLatest(ACTIONS.DOWNLOAD_USERS_REPORT, userSaga.downloadUsersReport)
  yield takeLatest(ACTIONS.GET_DASHBOARD_DATA, userSaga.getDashboardData)
  yield takeLatest(ACTIONS.ADD_REMOVE_ROLE, userSaga.addRemoveRole)
  
  //Notes
  yield takeLatest(ACTIONS.GET_USER_NOTES, userSaga.getUserNotes)
  yield takeLatest(ACTIONS.CREATE_USER_NOTE, userSaga.createUserNote)
  yield takeLatest(ACTIONS.DELETE_USER_NOTE, userSaga.deleteUserNote)
  yield takeLatest(ACTIONS.UPDATE_USER_NOTE, userSaga.updateUserNote)
  
  //Timezone
  yield takeLatest(ACTIONS.SET_TIMEZONE, timezoneSaga.setTimeZone)

  //Resources
  yield takeLatest(ACTIONS.CREATE_UPLOAD_RESOURCE, resourceSaga.createUploadResource)
  yield takeLatest(ACTIONS.UPLOAD_RESOURCE_FILE, resourceSaga.uploadResourceFile)

  //AWS
  yield takeLatest(ACTIONS.INIT_PRESIGNED_URL_MULTIPART_UPLOAD, awsSaga.initPresignedUrlMultipartUpload)
  yield takeLatest(ACTIONS.INIT_PRESIGNED_URL_SIMPLE_UPLOAD, awsSaga.initPresignedUrlSimpleUpload)
  yield takeLatest(ACTIONS.COMPLETE_MULTIPART_UPLOAD, awsSaga.completeMultiPartUpload)

  //Data Collection
  yield takeLatest(ACTIONS.CREATE_DATA_COLLECTION, (action) => genericSaga.genericHandler(action, (data: any) => genericPost(data, 'api/data-collection/create-data-collection')));
  yield takeLatest(ACTIONS.CREATE_QUESTION_SET, (action) => genericSaga.genericHandler(action, (data: any) => genericPost(data, 'api/data-collection/create-question-set')));
  yield takeLatest(ACTIONS.CREATE_QUESTION, (action) => genericSaga.genericHandler(action, (data: any) => genericPost(data, 'api/data-collection/create-question')));
  yield takeLatest(ACTIONS.GET_QUESTION_SETS, (action) => genericSaga.genericHandler(action, (data: any) => genericGet(data, 'api/data-collection/get-question-sets')));
  yield takeLatest(ACTIONS.GET_QUESTIONS, (action) => genericSaga.genericHandler(action, (data: any) => genericGet(data, 'api/data-collection/get-questions')));
  yield takeLatest(ACTIONS.GET_QUESTIONS_BY_UUID, (action) => genericSaga.genericHandler(action, (data: any) => genericGet(data, 'api/data-collection/get-questions-by-uuid')));
  yield takeLatest(ACTIONS.GET_MY_DATA_COLLECTIONS, (action) => genericSaga.genericHandler(action, (data: any) => genericGet(data, 'api/data-collection/get-my-data-collections')));
  yield takeLatest(ACTIONS.UPDATE_QUESTION_SET, (action) => genericSaga.genericHandler(action, (data: any) => genericPost(data, 'api/data-collection/update-question-set')));
  yield takeLatest(ACTIONS.UPDATE_QUESTION, (action) => genericSaga.genericHandler(action, (data: any) => genericPost(data, 'api/data-collection/update-question')));
  yield takeLatest(ACTIONS.UPDATE_QUESTION, (action) => genericSaga.genericHandler(action, (data: any) => genericPost(data, 'api/data-collection/update-question')));

  yield takeLatest(ACTIONS.CONFIRM_EMAIL, (action) => genericSaga.genericHandler(action, (data: any) => genericPost(data, 'api/auth/confirm-email')));

  //Form Recognizer
  yield takeLatest(ACTIONS.UPLOAD_941, formRecognizerSaga.upload941)
  yield takeLatest(ACTIONS.RETURN_941, formRecognizerSaga.return941)

  //Wage Calculations
  //yield takeLatest(ACTIONS.GET_WAGE_CALCULATIONS, (action) => genericSaga.genericHandler(action, (data: any) => genericGet(data, 'api/admin/get-wage-calculations')));
  yield takeLatest(ACTIONS.UPLOAD_WAGE_WORKSHEET, (action) => genericSaga.genericFileUpload(action, (data: any) => genericUploadPost(data, 'api/tax-calculation/upload-wage-worksheet')));


}


