import React, { useState } from "react";
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { createQuestion, createQuestionSet, updateQuestion, updateQuestionSet } from "../../../store/actions/dataCollectionActions";
import Loading from "../../../components/general/Loading";
import StandardModal from "../../../components/shared/StandardModal";
import { MARGIN_SMALL } from "../../../enums/layout-enums";
import { DATA_RESPONSE_TYPE } from "../../../shared/constants/data-collection.constants";
import { isNil } from "lodash";

interface IUpsertQuestion {
    mode: string
    setOpen: (dir: boolean) => void
    open: boolean
    initialValues: any
    questionSetId: number
}

const validate = (values: any) => {
    const errors = {} as any;
    if (!values.name) {
        errors.name = 'Required';
    }
    if (!values.value_type) {
        errors.value_type = 'Required';
    }
    if (!values.question_detail) {
        errors.question_detail = 'Required';
    }
    if (values.value_type === DATA_RESPONSE_TYPE.NUMBER || values.value_type === DATA_RESPONSE_TYPE.NUMBER_WITH_TEXT_ALIASES){
        if (isNil(values.min) || values.min === ""){
            errors.min = 'Required';
        }
        if (isNil(values.max || values.max === "")){
            errors.max = 'Required';
        }
        if (values.min >= values.max){
            errors.min = 'Less than max';
            errors.max = 'Greater than min';
        }
    }
    if (values.value_type === DATA_RESPONSE_TYPE.NUMBER_WITH_TEXT_ALIASES){
        if ((values.max - values.min) > 10){
            errors.min = 'Maximum 10 Values';
            errors.max = 'Maximum 10 Values';
        }
    }
    return errors;
};

const UpsertQuestionModal: React.FC<IUpsertQuestion> = (props) => {

    const formik = useFormik({
        initialValues: props.initialValues,
        validate,
        onSubmit: values => {
            console.log("submitted")
        },
    });
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch()

    const [processing, setProcessing] = useState(false)

    const closeModal = () => {
        setProcessing(false);
        props.setOpen(false)
    }

    const handleUpsertCall = () => {
        const upsertData = {...formik.values}
        upsertData.question_set_id = props.questionSetId
        delete upsertData.tableData
        delete upsertData.min
        delete upsertData.max
        const data = {} as any
		switch(formik.values.value_type){
			case DATA_RESPONSE_TYPE.NUMBER:
                data.min = formik.values.min
				data.max = formik.values.max
            break;
            case DATA_RESPONSE_TYPE.NUMBER_WITH_TEXT_ALIASES:
				data.min = formik.values.min
				data.max = formik.values.max
                data.aliases = getAliases()
			break;
		}
        // delete temporary keys that should not be on update object
        const deleteKeys = [] as any[]
        Object.keys(upsertData).forEach((key, index) => {
            if (key.includes("_alias")){
                deleteKeys.push(key)
            }
        });
        deleteKeys.forEach((key) => {
            delete upsertData[key]
        })
        upsertData.data = data;
        console.log("upsertData", upsertData)

        //return null;
        if (props.mode === 'create') {
            dispatch(createQuestion(upsertData, (data: any) => {
                closeModal()
                enqueueSnackbar(
                    `Question created.`,
                    { variant: "success" }
                );
            }, (data: any) => {
                setProcessing(false)
                enqueueSnackbar(
                    `Problem creating question.`,
                    { variant: "error" }
                );
            }))
        }
        else {
            dispatch(updateQuestion(upsertData, (data: any) => {
                closeModal()
                enqueueSnackbar(
                    `Question updated.`,
                    { variant: "success" }
                );
            }, (data: any) => {
                console.log("error data", data)
                setProcessing(false)
                enqueueSnackbar(
                    data.toString(),
                    { variant: "error" }
                );
            }))
        }
    }

    const getAliases = () => {

        const aliases = []
        for (let i = formik.values.min; i<=formik.values.max; i++){
            if (formik.values[i + "_alias"]){
                aliases.push({
                    number: i,
                    val: formik.values[i + "_alias"]
                })
            }
            else{
                aliases.push({
                    number: i,
                    val: ""
                })
            }
        }
        return aliases;

    }

    const getArrFromRange = () => {

        const returnVals = [] as any[]
        if (isNil(formik.values.min) || isNil(formik.values.max)){
            return returnVals
        }
        if (formik.values.max < formik.values.min){
            return returnVals
        }
        if (formik.values.max - formik.values.min > 10){
            return returnVals
        }
        for (let i = formik.values.min; i<=formik.values.max; i++){
            returnVals.push({
                number: i,
                val: ""
            })
        }
        return returnVals;

    }

    return (
        <>
            <Loading loading={processing} />
            <StandardModal
                title={props.mode === 'create' ? 'Create Question' : 'Edit Question'}
                open={props.open}
                onClose={() => props.setOpen(false)}
                actions={[{
                    title: props.mode === 'create' ? 'Create' : 'Save',
                    callback: () => {
                        formik.validateForm(formik.values).then((errors) => {
                            if (Object.keys(errors).length === 0) {
                                setProcessing(true)
                                handleUpsertCall()
                            }
                        })
                    }
                }]}
            >
                <div>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl fullWidth={true} margin={'dense'} error={formik.errors.name ? true : false} variant="standard">
                                    <TextField
                                        label="Name"
                                        fullWidth={true}
                                        multiline={true}
                                        size="small"
                                        name="name"
                                        onChange={formik.handleChange}
                                        style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                                        value={formik.values.name}
                                        variant="outlined"
                                    />
                                    {formik.errors.name ? <FormHelperText id="component-error-text">{formik.errors.name as string}</FormHelperText> : null}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
								<FormControl fullWidth margin={'dense'}>
									<InputLabel id="data-response-type">Question Type</InputLabel>
									<Select
										value={formik.values.value_type}
										name="value_type"
										label="Type"
                                        size="small"
										onChange={formik.handleChange}
										variant="outlined"
										style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
									>
										<MenuItem value={DATA_RESPONSE_TYPE.BOOLEAN}>Boolean</MenuItem>
                                        <MenuItem value={DATA_RESPONSE_TYPE.NUMBER}>Number</MenuItem>
                                        <MenuItem value={DATA_RESPONSE_TYPE.NUMBER_WITH_TEXT_ALIASES}>Number With Aliases</MenuItem>
									</Select>
									{formik.errors.value_type ? <FormHelperText id="component-error-text">{formik.errors.value_type as string}</FormHelperText> : null}
								</FormControl>
							</Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth={true} margin={'dense'} error={formik.errors.question_detail ? true : false} variant="standard">
                                    <TextField
                                        label="Detail"
                                        fullWidth={true}
                                        multiline={true}
                                        size="small"
                                        name="question_detail"
                                        onChange={formik.handleChange}
                                        style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                                        value={formik.values.question_detail}
                                        variant="outlined"
                                    />
                                    {formik.errors.question_detail ? <FormHelperText id="component-error-text">{formik.errors.question_detail as string}</FormHelperText> : null}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
								<FormControl fullWidth margin={'dense'}>
									{/* <InputLabel id="data-response-type">Page</InputLabel> */}
                                    <TextField
										label="Page"
										type={'number'}
										fullWidth={true}
										size="small"
										name="page"
										onChange={formik.handleChange}
										style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                                        value={formik.values.page}
										variant="outlined"
									/>
									{formik.errors.page ? <FormHelperText id="component-error-text">{formik.errors.page as string}</FormHelperText> : null}
								</FormControl>
							</Grid>
                            {(formik.values.value_type === DATA_RESPONSE_TYPE.NUMBER || formik.values.value_type === DATA_RESPONSE_TYPE.NUMBER_WITH_TEXT_ALIASES) && <>
                            <Grid item xs={6}>
								<FormControl fullWidth={true} margin={'dense'} error={formik.errors.min ? true : false} variant="standard">
									<TextField
										label="Min"
										type={'number'}
										fullWidth={true}
										size="small"
										name="min"
										onChange={formik.handleChange}
										style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
										value={formik.values.min}
										variant="outlined"
									/>
									{formik.errors.min ? <FormHelperText id="component-error-text">{formik.errors.min?.toString()}</FormHelperText> : null}
								</FormControl>
							</Grid>
                            <Grid item xs={6}>
								<FormControl fullWidth={true} margin={'dense'} error={formik.errors.max ? true : false} variant="standard">
									<TextField
										label="Max"
										type={'number'}
										fullWidth={true}
										size="small"
										name="max"
										onChange={formik.handleChange}
										style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
										value={formik.values.max}
										variant="outlined"
									/>
									{formik.errors.max ? <FormHelperText id="component-error-text">{formik.errors.max?.toString()}</FormHelperText> : null}
								</FormControl>
							</Grid>
                            </>}


                            {(formik.values.value_type === DATA_RESPONSE_TYPE.NUMBER_WITH_TEXT_ALIASES) && <>
                                {getArrFromRange().map((numAlias, index) => {
                                    return (
                                        <Grid key={index} item xs={12}>
                                            <FormControl fullWidth={true} margin={'dense'} error={formik.errors.min ? true : false} variant="standard">
                                            <TextField
                                                    label={numAlias.number + " Alias"}
                                                    fullWidth={true}
                                                    size="small"
                                                    name={numAlias.number + "_alias"}
                                                    onChange={formik.handleChange}
                                                    style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                                                    value={formik.values[numAlias.number + "_alias"]}
                                                    variant="outlined"
                                                />
                                                {formik.errors.min ? <FormHelperText id="component-error-text">{formik.errors.min?.toString()}</FormHelperText> : null}
                                            </FormControl>
                                        </Grid>
                                    )
                                })}
                            
                            </>}


                        </Grid>
                    </form>

                </div>

            </StandardModal>
        </>

    );

}
export default UpsertQuestionModal;