import { all, call, put, take } from 'redux-saga/effects';
import HttpClient, { objectToQuery } from '../../api/HttpClient';
import { ACTIONS } from '../../enums/actions';
import { IDispatchAction } from '../../interfaces/store';
import { HttpResponse } from '../../interfaces/store/api/HttpResponse';
import { dateFormatted } from '../../shared/utils/date-utils';
import GetFriendlyError from '../../utils/general';
import { return941 } from '../actions/formRecognizerActions';
const client = new HttpClient();


function convertToByteArray(input: any) {
  var sliceSize = 512;
  var bytes = [];

  for (var offset = 0; offset < input.length; offset += sliceSize) {
    var slice = input.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);

    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    bytes.push(byteArray);
  }

  return bytes;
}

async function upload941API(
  data: any,
): Promise<HttpResponse<{ x: string }>> {
  return client.uploadFiles('api/form-recognizer/upload_941', data);
}

async function return941API(
  data: any,
): Promise<HttpResponse<{ x: string }>> {
  console.log("ITS CALLING THE RETURN941", data);
  return client.getFile('api/form-recognizer/return_941' + objectToQuery(data));
}

const formRecognizerSaga = {

  * return941(action: IDispatchAction): Generator {
  try {
    const response = (yield call(return941API, action.payload)) as HttpResponse<any>;
    console.log("return941 response", response);
  }
  catch (e) {
  }
},

  * upload941(action: IDispatchAction): Generator {
    try {
      const response = (yield call(upload941API, action.payload)) as HttpResponse<any>;
      console.log("upload941 response", response);

      //var file = new Blob(convertToByteArray(response.data), { type: "application/pdf" });
//      const url = window.URL.createObjectURL(new Blob(convertToByteArray(response.data), {type: 'application/pdf'} ));

      // KINDOF WORKING
      // const link = document.createElement('a');
      // link.href = url;
      // const filename = `941-${dateFormatted(new Date(), 'yyyymmdd', '-')}.pdf`;
      // link.setAttribute('download', filename);
      // document.body.appendChild(link);
      // link.click();


//       const fileURL = URL.createObjectURL(file);

// window.open(fileURL);

      // const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'} ));
      // window.open(url);
      // const link = document.createElement('a');
      // link.href = url;
      // const filename = `941-${dateFormatted(new Date(), 'yyyymmdd', '-')}.pdf`;
      // link.setAttribute('download', filename);
      // document.body.appendChild(link);
      // link.click();
      if (action.onSuccess) {
        action.onSuccess(response.data);
      }
    }
    catch (e) {
      // if (action.onFail) {
      //   action.onFail();
      // }
      // const error = e as Error;
      // const friendlyError = GetFriendlyError(error.message);
      // yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
    // try {
    //   const response = (yield call(upload941API, action.payload)) as HttpResponse<any>;
    //   console.log("upload941 response", response);
    //   if (response?.status === 200 || response?.status === 201) {
    //     if (response?.data?.errors) {
    //       if (action.onFail) {
    //         action.onFail(response.data.errors)
    //       }
    //     }
    //     else {
    //       if (action.onSuccess) {
    //         action.onSuccess(response?.data?.meta);
    //       }
    //     }
    //   }
    // }
    // catch (e) {
    //   if (action.onFail) {
    //     action.onFail();
    //   }
    //   const error = e as Error;
    //   const friendlyError = GetFriendlyError(error.message);
    //   yield put({ type: ACTIONS.ERROR, message: friendlyError });
    // }
  },




};

export default formRecognizerSaga;
