
import { all, call, put } from "redux-saga/effects"; //call
import { ACTIONS } from "../../enums/actions";
import { IDispatchAction } from "../../interfaces/store";
import { HttpResponse } from "../../interfaces/store/api/HttpResponse";
import GetFriendlyError from "../../utils/general";
import { autoYields } from './schema'
import HttpClient, { objectToQuery } from '../../api/HttpClient';

const client = new HttpClient();


export async function genericGet(
    data: any,
    url: string
): Promise<HttpResponse<{ x: string }>> {
    return client.get(url + objectToQuery(data));
}

export async function genericPost(
    data: any,
    url: string
): Promise<HttpResponse<{ x: string }>> {
    return client.post(url, data);
}

export async function genericUploadPost(
    data: any, 
    url: string
): Promise<HttpResponse<{ x: string }>> {
    console.log("POST FILE", data, url)
    return client.uploadFiles(url, data);
}

const genericSaga = {

    * genericHandler(action: any, apiEndpoint: any): Generator {
        try {
            const response = (yield call(apiEndpoint, action.payload)) as HttpResponse<any>;
            if (response.status === 200 || response.status === 201) {
                const yields = autoYields(response.data);
                if (yields.length > 0) {
                    yield all(yields)
                }
                if (action.onSuccess) {
                    action.onSuccess(response.data);
                }
            }
            else {
                throw (response.status)
            }
        }
        catch (e) {
            if (action.onFail) {
                action.onFail(e);
            }
            const error = e as Error;
            const friendlyError = GetFriendlyError(error.message);
            yield put({ type: ACTIONS.ERROR, message: friendlyError });
        }
    },

    *genericFileUpload(action: any, genPostFunction: any): Generator {
        try {
            const response = (yield call(genPostFunction, action.payload)) as HttpResponse<any>;
            if (response?.status === 200 || response?.status === 201) {
                if (response?.data?.errors) {
                    if (action.onFail) {
                        action.onFail(response.data.errors);
                    }
                } else {
                    if (action.onSuccess) {
                        console.log("action success in genericFileUpload", response)
                        action.onSuccess(response?.data);
                    }
                }
            }
        } catch (e) {
            if (action.onFail) {
                action.onFail();
            }
            const error = e as Error;
            const friendlyError = GetFriendlyError(error.message);
            yield put({ type: ACTIONS.ERROR, message: friendlyError });
        }
    },

}

export default genericSaga;